import { getDomain as P } from "@intergamma/common/formula";
import C from "js-cookie";
import { Amplify as A } from "aws-amplify";
import { useMemo as I, useState as S } from "react";
import { dispatchAdobeEvent as m } from "@intergamma/adobe-tracking";
import { signIn as v, confirmSignIn as M } from "aws-amplify/auth";
import { useMutation as k, useQueryClient as O } from "@tanstack/react-query";
import { useTranslation as R } from "react-i18next";
import { c as T, l as d } from "./index-B17f4NLr.js";
import { useConfig as y } from "@intergamma/config";
import { c as U } from "./index-C2EW4nef.js";
const F = (e) => {
  const t = typeof document < "u" && document.location.href.match(/\/\/([^:/]+)/), o = (t ? t[1] : "").indexOf("acceptatie") < 0;
  switch (e) {
    case "gamma_be":
      return {
        userPoolId: o ? "eu-west-1_KwrkK0ufX" : "eu-west-1_j0JUmjRMW",
        // GAMMA BE ACC - USER POOL ID
        userPoolClientId: o ? "6bi3hbinnkcq1h12ctlige9f08" : "1te9tbna7h0sd6bemnevublnts"
        // GAMMA BE ACC - CLIENT ID
      };
    case "karwei_nl":
      return {
        userPoolId: o ? "eu-west-1_Ogv9zM2Zp" : "eu-west-1_AzWkTXNK8",
        // KARWEI ACC - USER POOL ID
        userPoolClientId: o ? "6lqki7p47e0q3etbn87pvb56op" : "7crtb2r6sfb9spi854qq0qcp1b"
        // KARWEI ACC - CLIENT ID
      };
    case "gamma_nl":
    default:
      return {
        userPoolId: o ? "eu-west-1_OHzUYP2T4" : "eu-west-1_Sm2PRyfmS",
        // GAMMA NL ACC - USER POOL ID
        userPoolClientId: o ? "78km7bhfrsa50ajiebbi8ng65q" : "64pji0q6h1armolasivbn51ftq"
        // GAMMA NL ACC - CLIENT ID
      };
  }
};
function N(e) {
  const t = C.get();
  Object.keys(t).forEach((r) => {
    r.startsWith("CognitoIdentityServiceProvider") && C.remove(r, { path: "/", domain: e });
  });
}
function J(e) {
  N(P(e)), A.configure({
    Auth: {
      Cognito: F(e)
    }
  });
}
function E(e) {
  const t = U(e, {
    withCredentials: !0
  });
  return {
    async getGatewayCSRF() {
      return (await t.get(
        "/gateway/session"
      )).csrfToken;
    },
    async login(r) {
      const o = await this.getGatewayCSRF();
      return t.post("/gateway/addcard/login", r, {
        headers: {
          "X-IG-CSRF-TOKEN": o
        }
      });
    }
  };
}
const q = () => {
  const { MYACCOUNT_URL: e } = y(), t = I(
    () => E(e),
    [e]
  );
  return {
    legacyLogin: (o) => new Promise((a, f) => {
      t.login(o).then(() => a()).catch(() => f());
    })
  };
};
function H() {
  const { MYACCOUNT_URL: e } = y(), t = I(
    () => T(e),
    [e]
  );
  return k({
    mutationFn: t.preferences,
    throwOnError: !1
  });
}
function V(e = "loginHeader", t = !0) {
  const { t: r } = R(["ig-header", "register", "login-form"]), [o, a] = S(!1), [f, h] = S(), p = O(), { mutate: _ } = H(), { legacyLogin: L } = q();
  return {
    authenticate: ({
      body: n,
      disableAdobeEvent: w
    }) => (a(!0), n.password ? new Promise((l, u) => {
      v({
        username: n.customerId,
        password: n.password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH"
        }
      }).then(() => (w || m({
        type: e === "loginHeader" ? "login_header" : "login",
        data: {
          user_selected_element: "password",
          kdbid: n.customerId,
          kdbid_hashed: n.hashedId
        }
      }), n.signup || _("PASSWORD"), a(!1), t && p.resetQueries(), d.info("Login", {
        details: "Successful"
      }), l())).catch((g) => {
        const { name: i, message: s } = g;
        L({ email: n.email, password: n.password }).then(() => (a(!1), t && p.resetQueries(), d.info("Legacy login", {
          details: "Successful"
        }), l)).catch(() => {
          d.error("Login", {
            details: "Failed",
            errorDetails: `${i}: ${s}`
          }), m({
            type: e === "loginHeader" ? "login_error_header" : "login_error",
            data: {
              user_message_type: `${i}: ${s}`
            }
          });
          let c = r("ig-header:loginSomethingWentWrong");
          return i === "NotAuthorizedException" && (c = r("ig-header:loginInvalidPassword"), s === "Password attempts exceeded" && (c = r("ig-header:loginTooManyAttempts"))), i === "UserNotFoundException" && (c = r("ig-header:loginUserNotFound")), a(!1), h(c), u();
        });
      });
    }) : new Promise((l, u) => {
      M({
        challengeResponse: n.verificationCode ?? ""
      }).then((g) => {
        const { isSignedIn: i, nextStep: s } = g;
        return a(!1), i ? (d.info("Login via code", {
          details: "Successful"
        }), _("CODE"), w || m({
          type: e === "loginHeader" ? "login_header" : "login",
          data: {
            user_selected_element: "code",
            kdbid: n.customerId,
            kdbid_hashed: n.hashedId
          }
        }), t && p.resetQueries(), l()) : (s.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE" && (d.warn("Login via code", {
          details: "Invalid code"
        }), h(r("register:errorNotValidCode"))), u());
      }).catch((g) => {
        const { name: i, message: s } = g;
        m({
          type: e === "loginHeader" ? "login_error_header" : "login_error",
          data: {
            user_message_type: `${i}: ${s}`
          }
        });
        let c = r("ig-header:loginSomethingWentWrong");
        return i === "NotAuthorizedException" ? (c = r("login-form:loginCodeMaxAttempts"), d.error("Login via code", {
          details: "Reached max attempts",
          errorDetails: `${i}: ${s}`
        })) : d.error("Login via code", {
          details: "Failed",
          errorDetails: `${i}: ${s}`
        }), a(!1), h(c), u();
      });
    })),
    error: f,
    isLoading: o
  };
}
export {
  J as c,
  F as g,
  V as u
};
