import { ReactNode, forwardRef, useId } from "react";

import { cn } from "@intergamma/common/cn";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { focusOutline } from "../helpers/focusOutline";

import { Description } from "./Description";
import { Error } from "./Error";

type CheckboxFieldProps = CheckboxPrimitive.CheckboxProps & {
  description?: ReactNode;
  error?: ReactNode;
  label: ReactNode;
};

export const CheckboxField = forwardRef<HTMLButtonElement, CheckboxFieldProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function CheckboxField(
    { className, description, error, label, disabled, onChange, ...props },
    ref,
  ) {
    const id = useId();

    return (
      <div className={cn("flex flex-col", className)}>
        <div
          className={cn(
            "relative flex w-full gap-2 text-100/6 gamma:text-ignew-functional-primary-800 karwei:text-brand-primary",
            disabled &&
              "gamma:text-ignew-neutral-400 karwei:text-ignew-neutral-600",
          )}
        >
          <CheckboxPrimitive.Root
            className={cn(
              "grid size-6 shrink-0 place-items-center border bg-white outline-none gamma:rounded-md gamma:border-ignew-neutral-200 karwei:border-ignew-neutral-100",
              "hover:bg-ignew-neutral-25",
              focusOutline,
              disabled &&
                "!border-opacity-0 gamma:!bg-ignew-neutral-50 karwei:!bg-ignew-neutral-100",
              !disabled &&
                "cursor-pointer text-white data-[state=checked]:!border-brand-primary data-[state=checked]:bg-brand-primary",
            )}
            id={id}
            disabled={disabled}
            ref={ref}
            {...props}
          >
            <CheckboxPrimitive.Indicator>
              <svg
                className="karwei:hidden"
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.33795 0.876399C9.82198 1.36042 9.82198 2.14518 9.33795 2.6292L3.96715 8L0.662044 4.69489C0.178022 4.21087 0.178022 3.42611 0.662044 2.94209C1.14607 2.45807 1.93082 2.45807 2.41484 2.94209L3.96715 4.4944L7.58516 0.876399C8.06918 0.392377 8.85393 0.392377 9.33795 0.876399Z"
                  fill="currentColor"
                />
              </svg>
              <svg
                className="gamma:hidden"
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.75 4.41667L4.61842 8.5L11.25 1.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
              </svg>
            </CheckboxPrimitive.Indicator>
          </CheckboxPrimitive.Root>
          <label className={cn(!disabled && "cursor-pointer")} htmlFor={id}>
            {label}
          </label>
        </div>
        {error && <Error className="mt-1">{error}</Error>}
        {!error && description && (
          <Description className="mt-1">{description}</Description>
        )}
      </div>
    );
  },
);
