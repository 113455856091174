import {
  ReactNode,
  ComponentProps,
  forwardRef,
  useId,
  ComponentPropsWithRef,
} from "react";

import { cn } from "@intergamma/common/cn";

import { Error } from "./Error";
import { Description } from "./Description";
import { Label } from "./Label";
import { inputStyles } from "./styles";

/**
 * The length of the verification code that the user needs to enter.
 */
export const CODE_LENGTH: 4 | 6 = 6;

type CodeFieldProps = ComponentPropsWithRef<"input"> & {
  label?: string;
  info?: ComponentProps<typeof Label>["info"];
  description?: ReactNode;
  error?: ReactNode;
  disabled?: boolean;
};

export const CodeField = forwardRef<HTMLInputElement, CodeFieldProps>(
  ({ label, description, error, info, ...inputProps }, ref) => {
    const id = useId();

    return (
      <div className="grid gap-1 text-center">
        {label && (
          <Label
            htmlFor={id}
            info={info}
            optional={false}
            className="w-full text-center font-bold"
          >
            {label}
          </Label>
        )}
        <div>
          <input
            id={id}
            ref={ref}
            maxLength={CODE_LENGTH}
            data-testid="code-input"
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            className={cn(
              inputStyles({ error, type: "number" }),
              "text-center tracking-[0.8rem]",
            )}
            placeholder={Array(CODE_LENGTH).fill("_").join("")}
            autoComplete="one-time-code"
            data-1p-ignore
            data-lp-ignore
            {...inputProps}
          />
        </div>
        {error && typeof error !== "boolean" && (
          <Error className="mt-1">{error}</Error>
        )}
        {!error && description && (
          <Description className="mt-1">{description}</Description>
        )}
      </div>
    );
  },
);
