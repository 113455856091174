function I() {
  return !!(process.env.VITE_FORCE_KIOSK && process.env.VITE_FORCE_KIOSK === "true" || process.env.NEXT_PUBLIC_FORCE_KIOSK && process.env.NEXT_PUBLIC_FORCE_KIOSK === "true");
}
function a(e, i, t) {
  var o;
  if (!("chrome" in window) || !((o = chrome == null ? void 0 : chrome.runtime) != null && o.sendMessage))
    throw new Error("Chrome is not defined");
  return new Promise(
    (s, r) => (
      // eslint-disable-next-line no-promise-executor-return
      chrome.runtime.sendMessage(
        e,
        { key: i, payload: t },
        (n) => n ? "data" in n ? s(n.data) : "error" in n ? r(n.error) : r() : r()
      )
    )
  );
}
function u() {
  return process.env.VITE_KIOSK_EXTENSION_ID ? process.env.VITE_KIOSK_EXTENSION_ID : process.env.NEXT_PUBLIC_KIOSK_EXTENSION_ID ? process.env.NEXT_PUBLIC_KIOSK_EXTENSION_ID : document.location.host.includes("acceptatie") ? "bfblbleokjnmiigkafjmpgkmdeojfhka" : "hmgofimfefbcoomlplagdbofhglfkkpl";
}
async function _() {
  var e;
  if (I() || typeof window < "u" && ((e = window == null ? void 0 : window.navigator) != null && e.userAgent.includes("IG-Kiosk")))
    return !0;
  try {
    return await a(
      u(),
      "VALIDATE_IS_KIOSK"
    );
  } catch {
    return !1;
  }
}
export {
  _ as a,
  u as g,
  I as i,
  a as s
};
