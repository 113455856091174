import { formulaConfig } from "config/formula"
import type { ImgHTMLAttributes } from "react"

const defaultDamImageSet = [
  { format: 23, naturalWidth: 290, pixelDensity: 1 },
  { format: 123, naturalWidth: 355, pixelDensity: 2 },
]

interface GetDamImageSrcOptions {
  celumId: string
  format: number
}

export function getDamImageSrc({ celumId, format }: GetDamImageSrcOptions): ImgHTMLAttributes<HTMLImageElement>["src"] {
  return `${formulaConfig.STATIC_URL}/dam/${celumId}/${format}`
}

interface GetDamImageSrcSetOptions {
  celumId: string
  damImageSet?: typeof defaultDamImageSet
}
// see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-srcset
export function getDamImageSrcSet({
  celumId,
  damImageSet = defaultDamImageSet,
}: GetDamImageSrcSetOptions): ImgHTMLAttributes<HTMLImageElement>["srcSet"] {
  return damImageSet
    .map((damImg) => `${getDamImageSrc({ celumId, format: damImg.format })} ${damImg.pixelDensity}x`)
    .join(", ")
}
