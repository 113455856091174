import { useAuthenticate } from "@intergamma/account"
import { CartIconNavigation, HeaderContainer, IconsNavigation, UserIconNavigation } from "@intergamma/header"
import { useCheckoutPageQuery } from "api/checkout-page"
import { useCurrentCustomerQuery } from "api/current-customer"
import { useSessionDataQuery } from "api/myaccount/session-data"
import { useNumberOfProductsQuery } from "api/number-of-products"
import { formulaConfig } from "config/formula"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

export function SimplifiedHeader() {
  const currentCustomerQuery = useCurrentCustomerQuery()
  const { authenticate, error, isLoading } = useAuthenticate("loginHeader", false)
  const checkoutPageQuery = useCheckoutPageQuery()
  const sessionDataQuery = useSessionDataQuery()
  const numberOfProductsQuery = useNumberOfProductsQuery()
  const { t } = useTranslation("ig-header")

  const isLoginStatusShown = sessionDataQuery.isSuccess && !sessionDataQuery.data?.isLoggedIn

  return (
    <header>
      <HeaderContainer variant="simple" formula={formulaConfig}>
        <IconsNavigation variant="simple">
          {isLoginStatusShown && (
            <UserIconNavigation
              user={sessionDataQuery.data}
              isLoading={isLoading}
              onLogin={async (body) => {
                await authenticate(body)
                await Promise.all([
                  checkoutPageQuery.refetch(),
                  currentCustomerQuery.refetch(),
                  sessionDataQuery.refetch(),
                ])
                toast.success(t("loginSuccess"))
              }}
              errorMessage={error}
            />
          )}

          <CartIconNavigation numberOfProducts={numberOfProductsQuery.data ?? 0} />
        </IconsNavigation>
      </HeaderContainer>
    </header>
  )
}
