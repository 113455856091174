// @todo More properties to be added later
export type Formula = {
  id: "karwei_nl" | "gamma_nl" | "gamma_be";
  brand: "gamma" | "karwei";
  brandName: "GAMMA" | "Karwei";
  baseDomain: string;
  contentfulSpaceId: string;
  contentfulToken: string;
};

export function getDomain(id: Formula["id"]) {
  const subDomain =
    typeof window !== "undefined" &&
    window.location.hostname.includes("acceptatie")
      ? ".acceptatie"
      : "";

  if (id === "gamma_be") return `${subDomain}.gamma.be`;
  if (id === "gamma_nl") return `${subDomain}.gamma.nl`;
  if (id === "karwei_nl") return `${subDomain}.karwei.nl`;

  throw Error("Couldn't detect domain by formula id");
}
