import { jsxs as l, jsx as o } from "react/jsx-runtime";
import { forwardRef as i, createRef as u } from "react";
import { cn as c } from "@intergamma/common/cn";
import * as r from "@radix-ui/react-dialog";
import { DialogTitle as x } from "@radix-ui/react-dialog";
import { DialogRoot as h } from "./DialogRoot.js";
import { Dialog as X, DialogContent as q } from "./Dialog.js";
import { DialogCloseIcon as F, StyledCloseIcon as G } from "./DialogCloseIcon.js";
import { DialogClose as J } from "./DialogClose.js";
import { DialogDescription as L } from "./DialogDescription.js";
import { DialogTitle as V } from "./DialogTitle.js";
import { DialogTrigger as Z, UnstyledDialogDescription as ee, UnstyledDialogTitle as oe, UnstyledDialogTrigger as te } from "./DialogTrigger.js";
import { X as w } from "react-feather";
function U({
  trigger: e,
  animationDirection: t = "ltr",
  children: a,
  ...n
}) {
  return /* @__PURE__ */ l(h, { ...n, children: [
    e,
    /* @__PURE__ */ o(v, { $animationDirection: t, children: a })
  ] });
}
const D = i(
  ({ children: e, $animationDirection: t, container: a, ...n }, s) => /* @__PURE__ */ l(r.Portal, { container: a, forceMount: n.forceMount, children: [
    /* @__PURE__ */ o(
      _,
      {
        ...n,
        "data-testid": "dialog-drawer",
        animationDirection: t,
        ref: s,
        children: e
      }
    ),
    /* @__PURE__ */ o(y, { forceMount: n.forceMount })
  ] })
), y = i((e, t) => /* @__PURE__ */ o(
  r.Overlay,
  {
    ...e,
    ref: t,
    className: c(
      "fixed inset-0 z-[9999] bg-[hsla(200,60%,12%,0.75)]",
      "motion-safe:animate-dialog-overlay-show"
    )
  }
)), _ = i(
  ({ animationDirection: e = "ltr", ...t }, a) => /* @__PURE__ */ o(
    r.Content,
    {
      ...t,
      ref: a,
      className: c(
        "fixed inset-y-0 z-[99999] w-[90vw] max-w-[500px] overflow-auto bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,0.35),_0_10px_20px_-15px_hsla(206,22%,7%,0.2)]",
        e === "rtl" ? "left-auto right-0" : "left-0 right-auto",
        e === "rtl" ? "motion-safe:animate-dialog-content-show-rtl" : "motion-safe:animate-dialog-content-show-ltr",
        "focus:outline-none",
        "data-[state=closed]:hidden"
      ),
      children: t.children
    }
  )
), v = D;
function p() {
  return /* @__PURE__ */ o(C, { "data-testid": "close-button", children: /* @__PURE__ */ o(b, {}) });
}
const C = ({ ...e }) => /* @__PURE__ */ o(
  r.Close,
  {
    ...e,
    className: "absolute right-4 top-4 h-auto cursor-pointer border-none bg-transparent p-0 leading-none shadow-none focus:shadow-none"
  }
), b = () => /* @__PURE__ */ o(w, { className: "text-[#003878]" });
function $({
  trigger: e,
  wide: t = !1,
  children: a,
  container: n,
  dialogTitle: s,
  ...d
}) {
  const f = u(), m = (g) => {
    g.target !== f.current && g.preventDefault();
  };
  return /* @__PURE__ */ l(h, { ...d, children: [
    e,
    /* @__PURE__ */ o(r.Portal, { container: n, children: /* @__PURE__ */ o(N, { ref: f, children: /* @__PURE__ */ l(
      O,
      {
        onInteractOutside: m,
        "data-testid": "dialog-content",
        wide: t,
        children: [
          s && /* @__PURE__ */ o(x, { className: "sr-only", children: s }),
          /* @__PURE__ */ o(p, {}),
          a
        ]
      }
    ) }) })
  ] });
}
const N = i((e, t) => /* @__PURE__ */ o(
  r.Overlay,
  {
    ...e,
    ref: t,
    className: c(
      "fixed inset-0 z-[9999] flex flex-col items-center bg-[hsla(200,60%,12%,0.75)]",
      "overflow-y-auto py-8 backdrop-blur-[2px]",
      "motion-safe:animate-dialog-overlay-show"
    )
  }
)), O = i(({ wide: e = !1, children: t, ...a }, n) => /* @__PURE__ */ o(
  r.Content,
  {
    ...a,
    ref: n,
    className: c(
      "relative z-[99999] m-auto rounded-[0.8rem] bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,0.35),_0_10px_20px_-15px_hsla(206,22%,7%,0.2)]",
      "w-[90vw]",
      e ? "max-w-[690px]" : "max-w-[348px]",
      "animate-dialog-content-show",
      "focus:outline-none"
    ),
    children: t
  }
));
function B({
  trigger: e,
  children: t,
  container: a,
  dialogTitle: n,
  ...s
}) {
  const d = u(), f = (m) => {
    m.target !== d.current && m.preventDefault();
  };
  return /* @__PURE__ */ l(h, { ...s, children: [
    e,
    /* @__PURE__ */ o(r.Portal, { container: a, children: /* @__PURE__ */ o(I, { ref: d, children: /* @__PURE__ */ l(
      z,
      {
        onInteractOutside: f,
        "data-testid": "dialog-content",
        children: [
          n && /* @__PURE__ */ o(x, { className: "sr-only", children: n }),
          /* @__PURE__ */ l(R, { children: [
            /* @__PURE__ */ o(p, {}),
            t
          ] })
        ]
      }
    ) }) })
  ] });
}
const I = i((e, t) => /* @__PURE__ */ o(
  r.Overlay,
  {
    ...e,
    ref: t,
    className: c(
      "fixed inset-0 z-[9999] flex flex-col justify-end overflow-y-auto bg-[hsla(200,60%,12%,0.75)] backdrop-blur-[2px]",
      "motion-safe:animate-dialog-overlay-show"
    )
  }
)), z = i((e, t) => /* @__PURE__ */ o(
  r.Content,
  {
    ...e,
    ref: t,
    className: c(
      "relative z-[99999] h-[93svh] w-full rounded-[0.8rem_0.8rem_0_0] bg-white",
      "motion-safe:animate-dialog-content-expand-height",
      "focus:outline-none"
    ),
    children: e.children
  }
)), R = ({ children: e }) => /* @__PURE__ */ o("div", { className: "h-auto w-full rounded-[0.8rem_0.8rem_0_0] bg-white", children: e });
export {
  X as Dialog,
  B as DialogBottomSheet,
  J as DialogClose,
  F as DialogCloseIcon,
  q as DialogContent,
  L as DialogDescription,
  $ as DialogExtended,
  h as DialogRoot,
  V as DialogTitle,
  Z as DialogTrigger,
  U as Drawer,
  v as DrawerContent,
  G as StyledCloseIcon,
  ee as UnstyledDialogDescription,
  oe as UnstyledDialogTitle,
  te as UnstyledDialogTrigger
};
