import {
  ComponentProps,
  ComponentPropsWithRef,
  ReactNode,
  forwardRef,
  useId,
} from "react";

import { cn } from "@intergamma/common/cn";

import { ChevronDown } from "../icons/ChevronDown";

import { Description } from "./Description";
import { Error } from "./Error";
import { Label } from "./Label";
import { inputStyles } from "./styles";

type SelectFieldProps = ComponentPropsWithRef<"select"> & {
  label: string;
  description?: ReactNode;
  error?: ReactNode;
  info?: ComponentProps<typeof Label>["info"];
  optional?: ComponentProps<typeof Label>["optional"];
  readOnly?: boolean;
};

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function SelectField(
    {
      children,
      className,
      description,
      error,
      info,
      label,
      optional,
      ...selectProps
    },
    ref,
  ) {
    const id = useId();

    return (
      <div className={cn("flex flex-col", className)}>
        <Label htmlFor={id} info={info} optional={optional}>
          {label}
        </Label>
        <div className="relative mt-0.5">
          <select
            {...selectProps}
            id={id}
            ref={ref}
            className={cn(inputStyles({ error }), "mt-0 appearance-none pr-14")}
          >
            {children}
          </select>
          {!selectProps.disabled && (
            <div className="pointer-events-none absolute right-4 top-0 grid h-full place-items-center">
              <ChevronDown />
            </div>
          )}
        </div>
        {error && <Error className="mt-1">{error}</Error>}
        {!error && description && (
          <Description className="mt-1">{description}</Description>
        )}
      </div>
    );
  },
);
