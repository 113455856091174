import { createHttpClient as D } from "@intergamma/account/login";
import { B as Ne, a as we, b as Se, c as Ce, d as ke, e as Ae, U as E, S as te, t as re, T as ie, f as Oe } from "./LoadingHeader-BhKP8wH1.js";
import { C as At, F as Ot, H as Et, I as Lt, g as _t, L as Tt, h as Mt, i as It, j as Dt } from "./LoadingHeader-BhKP8wH1.js";
import { useTranslation as f } from "react-i18next";
import { useTranslation as Ut } from "react-i18next";
import { jsx as n, jsxs as l } from "react/jsx-runtime";
import { Container as Ee } from "@intergamma/container";
import { useState as p, useEffect as y, useRef as R, useMemo as w, useCallback as X, lazy as _, Suspense as F, Children as Le, isValidElement as _e, cloneElement as Te } from "react";
import { useLegacyTheme as Y, useFormula as Me } from "@intergamma/theme/provider";
import { useQuery as O, keepPreviousData as se, useQueryClient as Ie, useMutation as De } from "@tanstack/react-query";
import { cn as d } from "@intergamma/common/cn";
import { DialogRoot as Be, UnstyledDialogTrigger as Ue, DrawerContent as ze, DialogTitle as Re, DialogBottomSheet as oe, DialogExtended as ae, DialogTrigger as M } from "@intergamma/dialog";
import { DropdownRoot as $, DropdownTrigger as q, DropdownContent as K } from "@intergamma/dropdown";
import { dispatchAdobeEvent as A } from "@intergamma/adobe-tracking";
import { MapPin as le, LogOut as ce, ChevronRight as B, ArrowLeft as ue, X as de, ChevronDown as Fe, ChevronUp as Ye, Star as $e } from "react-feather";
import { Anchor as N } from "@intergamma/anchor";
import { useConfig as x } from "@intergamma/config";
import { MainSearchBar as P, SearchBar as qe } from "@intergamma/search-bar";
import { ListItem as L } from "@intergamma/list";
import { localStorage as J } from "@intergamma/storage";
function Q(e) {
  var i;
  const r = D(e.SHOPFRONT_URL, {
    withCredentials: !0
  }), t = ((i = e.locales) == null ? void 0 : i[0].split(/[_-]/)[0]) ?? "nl";
  return {
    getCategories(o) {
      return r.get(e.country === "be" ? `/${t}/resources/menu/${o}` : `/resources/menu/${o}`).then(
        (s) => s.map(
          (a) => ({
            ...a,
            type: "category",
            dataUrl: Ke(a.dataUrl)
          })
        )
      );
    },
    getCategoryTree() {
      function o(s) {
        var a, c;
        return {
          ...s,
          type: "category",
          url: `${e.country === "be" ? `/${t}` : ""}/assortiment${s.categoryUrl}`,
          dataUrl: s.categoryUrl || "",
          subCategories: (a = s.subCategories) == null ? void 0 : a.map(o),
          hasSubCategories: !!((c = s.subCategories) != null && c.length)
        };
      }
      return r.get(
        e.country === "be" ? `/${t}/resources/categories/menu` : "/resources/categories/menu"
      ).then((s) => s.categories).then((s) => ({
        categories: [...s].map(o).sort((a, c) => a.name < c.name ? -1 : 1)
      }));
    }
  };
}
function Ke(e) {
  return e.replace("https://www.acceptatie.gamma.nl/resources/menu/", "").replace("https://www.gamma.nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/fr/resources/menu/", "").replace("https://www.gamma.be/nl/resources/menu/", "").replace("https://www.gamma.be/fr/resources/menu/", "").replace("https://www.acceptatie.karwei.nl/resources/menu/", "").replace("https://www.karwei.nl/resources/menu/", "");
}
function Pe({ category: e, onSelect: r }) {
  return /* @__PURE__ */ n(
    "li",
    {
      className: d(
        "m-0 block h-16 break-inside-avoid border-b gamma:border-ignew-neutral-100 karwei:border-[#dce1e8] lg:flex lg:h-10 lg:items-center lg:border-none",
        e.divider && "mb-10 lg:mb-0"
      ),
      children: /* @__PURE__ */ l(
        "a",
        {
          className: d(
            "group m-0 flex size-full grow cursor-pointer items-center rounded-lg border-none bg-transparent py-4 text-left text-[1em] leading-[1em] text-brand-primary no-underline",
            "hover:underline",
            "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400",
            "lg:w-auto lg:grow-0 lg:p-0",
            "justify-between px-4 lg:justify-normal"
          ),
          href: e.url,
          onClick: (t) => {
            e.hasSubCategories && (r(e), t.preventDefault());
          },
          children: [
            /* @__PURE__ */ l("div", { className: "flex gap-2", children: [
              e.icon === "store" && /* @__PURE__ */ n(le, { className: "!size-4 align-middle" }),
              e.icon === "logout" && /* @__PURE__ */ n(ce, { className: "align-middle" }),
              e.name
            ] }),
            e.hasSubCategories && /* @__PURE__ */ n(
              B,
              {
                className: d(
                  "ml-1 size-6 text-ignew-neutral-300 transition motion-safe:group-hover:translate-x-[5px] lg:right-[inherit] lg:size-4 lg:text-brand-primary",
                  "karwei:!stroke-1 karwei:text-ignew-neutral-600"
                )
              }
            )
          ]
        }
      )
    }
  );
}
function Qe({ categories: e, onSelect: r }) {
  return /* @__PURE__ */ n("div", { className: "relative", children: /* @__PURE__ */ n("div", { className: "overflow-hidden lg:min-h-[205px]", children: /* @__PURE__ */ n("ul", { className: "list-none columns-1 gap-x-5 p-0 text-[1em] lg:mx-0 lg:columns-4 lg:px-5 lg:pb-5 lg:text-[0.9em]", children: e.map((t) => /* @__PURE__ */ n(
    Pe,
    {
      category: t,
      onSelect: r
    },
    t.uid
  )) }) }) });
}
function He({ lastBreadcrumb: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ n(
    "div",
    {
      className: d(
        "h-16 rounded-b-lg pb-[15px] lg:h-auto lg:px-5 lg:py-[15px] lg:text-75/normal gamma:lg:bg-[#f0f2f6] karwei:lg:bg-[#f1f4f8]",
        "border-b gamma:border-ignew-neutral-100 karwei:border-[#dce1e8]"
      ),
      children: /* @__PURE__ */ n(
        N,
        {
          className: "flex items-center p-4 no-underline hover:underline lg:p-0",
          href: e.url,
          children: e.uid === "root" || e.uid === "root-mobile" ? r("ShowEntireCatalog") : r("ShowAllIn", { name: e.name })
        }
      )
    }
  );
}
function je({
  breadcrumbs: e,
  onBack: r,
  onClose: t
}) {
  const { t: i } = f("ig-header"), o = e.length;
  return /* @__PURE__ */ l("div", { className: "mb-3 flex items-center gap-2 border-b border-ignew-neutral-100 p-4 md:mt-[10px] lg:mb-2 lg:border-none lg:p-0 lg:px-[20px] lg:pt-[10px]", children: [
    o >= 2 && /* @__PURE__ */ n(
      "button",
      {
        className: d(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary lg:hidden",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: () => r(e[o - 2]),
        type: "button",
        children: /* @__PURE__ */ n(ue, { className: "size-6 stroke-1" })
      }
    ),
    /* @__PURE__ */ n("ul", { className: "m-0 flex h-8 flex-1 list-none items-center gap-2 text-ellipsis whitespace-nowrap", children: e.map((s, a) => /* @__PURE__ */ l(
      "li",
      {
        className: "group m-0 hidden items-center gap-2 p-0 last:flex lg:flex",
        children: [
          a === o - 1 ? /* @__PURE__ */ n(
            "span",
            {
              className: "text-[22px] font-bold text-brand-primary lg:text-[16px] lg:text-black",
              title: s.name,
              "data-testid": "header-navigation-breadcrumb",
              children: s.name
            }
          ) : /* @__PURE__ */ n(
            "a",
            {
              className: d(
                "rounded-lg text-inherit underline hover:text-brand-primary",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              href: s.url,
              onClick: (c) => {
                c.preventDefault(), r(s);
              },
              children: s.name
            }
          ),
          /* @__PURE__ */ n(B, { className: "size-4 group-last:hidden" })
        ]
      },
      s.uid
    )) }),
    t && /* @__PURE__ */ n(
      "button",
      {
        "data-testid": "header-navigation-close-button",
        className: d(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        type: "button",
        onClick: t,
        "aria-label": i("close"),
        children: /* @__PURE__ */ n(de, { className: "size-6 gamma:stroke-2 karwei:stroke-1" })
      }
    )
  ] });
}
function Z({
  onClose: e,
  onSelect: r,
  breadcrumbs: t,
  categories: i,
  onBack: o,
  lastBreadcrumb: s
}) {
  return /* @__PURE__ */ l("nav", { className: "overflow-hidden", children: [
    /* @__PURE__ */ n(
      je,
      {
        onBack: o,
        breadcrumbs: t,
        onClose: e
      }
    ),
    /* @__PURE__ */ n(Qe, { categories: i ?? [], onSelect: r }),
    s.type === "category" && /* @__PURE__ */ n(He, { lastBreadcrumb: s })
  ] });
}
function Ge({
  breadcrumbs: e,
  onBack: r,
  onClose: t
}) {
  const { t: i } = f("ig-header"), o = e.length;
  return /* @__PURE__ */ l("div", { className: "mb-3 flex items-center gap-2 border-b border-ignew-neutral-100 p-4 md:mt-[10px] lg:mb-2 lg:border-none lg:p-0 lg:px-[20px] lg:pt-[10px]", children: [
    o >= 2 && /* @__PURE__ */ n(
      "button",
      {
        className: d(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary lg:hidden",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: () => r(e[o - 2]),
        type: "button",
        children: /* @__PURE__ */ n(ue, { className: "size-6 stroke-1" })
      }
    ),
    /* @__PURE__ */ n("ul", { className: "m-0 flex h-8 flex-1 list-none items-center gap-2 text-ellipsis whitespace-nowrap", children: e.map((s, a) => /* @__PURE__ */ l(
      "li",
      {
        className: "group m-0 hidden items-center gap-2 p-0 last:flex lg:flex",
        children: [
          a === o - 1 ? /* @__PURE__ */ n(
            "span",
            {
              className: "text-[22px] font-bold text-brand-primary lg:text-[16px] lg:text-black",
              title: s.name,
              "data-testid": "header-navigation-breadcrumb",
              children: s.name
            }
          ) : /* @__PURE__ */ n(
            "a",
            {
              className: d(
                "rounded-lg text-inherit underline hover:text-brand-primary",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              href: s.url,
              onClick: (c) => {
                c.preventDefault(), r(s);
              },
              children: s.name
            }
          ),
          /* @__PURE__ */ n(B, { className: "size-4 group-last:hidden" })
        ]
      },
      s.uid
    )) }),
    t && /* @__PURE__ */ n(
      "button",
      {
        "data-testid": "header-navigation-close-button",
        className: d(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        type: "button",
        onClick: t,
        "aria-label": i("close"),
        children: /* @__PURE__ */ n(de, { className: "size-6 gamma:stroke-2 karwei:stroke-1" })
      }
    )
  ] });
}
function We({
  lastBreadcrumb: e
}) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ n(
    "div",
    {
      className: d(
        "h-16 rounded-b-lg pb-[15px] lg:h-auto lg:px-5 lg:py-[15px] lg:text-75/normal gamma:lg:bg-[#f0f2f6] karwei:lg:bg-[#f1f4f8]",
        "border-b gamma:border-ignew-neutral-100 karwei:border-[#dce1e8]"
      ),
      children: /* @__PURE__ */ n(
        N,
        {
          className: "flex items-center p-4 no-underline hover:underline lg:p-0",
          href: e.url,
          children: e.uid === "root" || e.uid === "root-mobile" ? r("ShowEntireCatalog") : r("ShowAllIn", { name: e.name })
        }
      )
    }
  );
}
function Ve({
  categories: e,
  onSelect: r,
  breadcrumbs: t,
  lastBreadcrumb: i,
  depth: o,
  className: s,
  parentCategory: a
}) {
  return /* @__PURE__ */ n("div", { className: d("relative", s), children: /* @__PURE__ */ n("div", { className: "overflow-hidden lg:min-h-[205px]", children: /* @__PURE__ */ n("ul", { className: "list-none columns-1 gap-x-5 p-0 text-[1em] lg:mx-0 lg:columns-4 lg:px-5 lg:pb-5 lg:text-[0.9em]", children: e.map((c) => /* @__PURE__ */ n(
    me,
    {
      category: c,
      onSelect: r,
      breadcrumbs: t,
      lastBreadcrumb: i,
      depth: o + 1,
      parentCategory: a
    },
    c.uid
  )) }) }) });
}
function me({
  category: e,
  onSelect: r,
  breadcrumbs: t,
  lastBreadcrumb: i,
  depth: o,
  parentCategory: s
}) {
  var g, h;
  const a = e.type === "category" && e.uid === "root-mobile", c = a ? "div" : "li", m = i.uid === (s == null ? void 0 : s.uid);
  return /* @__PURE__ */ l(
    c,
    {
      className: d(
        !a && m && "m-0 block h-16 break-inside-avoid border-b gamma:border-ignew-neutral-100 karwei:border-[#dce1e8] lg:flex lg:h-10 lg:items-center lg:border-none",
        m && e.divider && "mb-10 lg:mb-0"
      ),
      "data-category-uid": e.uid,
      children: [
        !a && /* @__PURE__ */ l(
          "a",
          {
            className: d(
              "group m-0 flex size-full grow cursor-pointer items-center rounded-lg border-none bg-transparent py-4 text-left text-[1em] leading-[1em] text-brand-primary no-underline",
              "hover:underline",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400",
              "lg:w-auto lg:grow-0 lg:p-0",
              "justify-between px-4 lg:justify-normal",
              !m && "hidden"
            ),
            href: e.url,
            onClick: (S) => {
              e.type === "category" && e.hasSubCategories && (r(e), S.preventDefault());
            },
            children: [
              /* @__PURE__ */ l("div", { className: "flex gap-2", children: [
                e.type === "menu-item" && e.icon === "store" && /* @__PURE__ */ n(le, { className: "!size-4 align-middle" }),
                e.type === "menu-item" && e.icon === "logout" && /* @__PURE__ */ n(ce, { className: "align-middle" }),
                e.name
              ] }),
              e.type === "category" && e.hasSubCategories && /* @__PURE__ */ n(
                B,
                {
                  className: d(
                    "ml-1 size-6 text-ignew-neutral-300 transition motion-safe:group-hover:translate-x-[5px] lg:right-[inherit] lg:size-4 lg:text-brand-primary",
                    "karwei:!stroke-1 karwei:text-ignew-neutral-600"
                  )
                }
              )
            ]
          }
        ),
        e.type === "category" && !!((g = e.subCategories) != null && g.length) && /* @__PURE__ */ n(
          Ve,
          {
            categories: e.subCategories,
            onSelect: r,
            breadcrumbs: t,
            lastBreadcrumb: i,
            depth: o,
            className: d(
              ((h = t[o]) == null ? void 0 : h.uid) === e.uid ? "" : "hidden"
            ),
            parentCategory: e
          }
        )
      ]
    }
  );
}
function Xe({
  onClose: e,
  onSelect: r,
  breadcrumbs: t,
  categories: i,
  onBack: o,
  lastBreadcrumb: s
}) {
  return /* @__PURE__ */ l("nav", { className: "overflow-hidden", children: [
    /* @__PURE__ */ n(
      Ge,
      {
        onBack: o,
        breadcrumbs: t,
        onClose: e
      }
    ),
    /* @__PURE__ */ n("ul", { children: /* @__PURE__ */ n(
      me,
      {
        category: {
          type: "category",
          uid: "root-mobile",
          categoryUrl: "#",
          name: "",
          url: "",
          subCategories: i || [],
          dataUrl: "",
          hasSubCategories: !!(i != null && i.length)
        },
        onSelect: r,
        breadcrumbs: t,
        lastBreadcrumb: s,
        depth: 0
      }
    ) }),
    s.type === "category" && /* @__PURE__ */ n(We, { lastBreadcrumb: s })
  ] });
}
function Je({
  menuItem: e,
  store: r
}) {
  const t = Y(), { t: i } = f("ig-header");
  if (e.type === "category")
    return [];
  if (e.uid === "root-mobile") {
    const o = [
      {
        uid: "showall",
        type: "menu-item",
        name: i("ShowEntireCatalog"),
        url: i("/catalog"),
        hasSubCategories: !1,
        divider: !0
      },
      {
        uid: "advice",
        type: "menu-item",
        name: i("Advice"),
        url: i("/advice"),
        hasSubCategories: !1
      },
      {
        uid: "services",
        type: "menu-item",
        name: i("ServicesMobile"),
        url: i("/services"),
        hasSubCategories: !1
      },
      {
        uid: "promotions",
        type: "menu-item",
        name: i("Promotions"),
        url: i("/promotions"),
        hasSubCategories: !1
      }
    ];
    return t.name === "karwei_nl" && o.push({
      uid: "inspiration",
      type: "menu-item",
      name: i("Inspiration"),
      url: i("/inspiration"),
      hasSubCategories: !1
    }), o.push({
      uid: "store",
      name: r != null && r.name ? r.name : i("Stores"),
      url: i("/stores"),
      hasSubCategories: !1,
      type: "menu-item",
      icon: "store",
      divider: !0
    }), o;
  }
  return [];
}
function ee({
  store: e,
  rootCategory: r
}) {
  const [t, i] = p([r]);
  y(() => {
    i([r]);
  }, [r]);
  const o = t[t.length - 1], s = Je({
    menuItem: o,
    store: e
  });
  function a(m) {
    const g = t.findIndex(
      (h) => h.uid === m.uid
    );
    g > -1 && i(t.slice(0, g + 1));
  }
  function c(m) {
    m.hasSubCategories && i([...t, m]);
  }
  return {
    simpleMenuItems: s,
    onBack: a,
    onSelect: c,
    breadcrumbs: t,
    setBreadcrumbs: i,
    lastBreadcrumb: o
  };
}
function Ze() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.add("panel-active");
}
function en() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.remove("panel-active");
}
function nn({
  categories: e,
  store: r,
  onSelect: t,
  forceMountMobileMenu: i,
  categoryTree: o
}) {
  const { t: s } = f("ig-header"), [a, c] = p(!1), [m, g] = p(!1), h = R(null), S = w(
    () => ({
      dataUrl: "categories",
      uid: "root",
      name: s("Catalog"),
      url: s("/catalog"),
      hasSubCategories: !0,
      type: "category"
    }),
    [s]
  ), C = w(
    () => ({
      uid: "root-mobile",
      name: s("Menu"),
      url: "#",
      hasSubCategories: !0,
      type: "menu-item"
    }),
    [s]
  ), {
    breadcrumbs: k,
    lastBreadcrumb: v,
    onBack: b,
    onSelect: be
  } = ee({ store: r, rootCategory: S }), {
    breadcrumbs: j,
    lastBreadcrumb: T,
    onBack: ve,
    onSelect: ye,
    simpleMenuItems: G
  } = ee({ store: r, rootCategory: C });
  function W(u) {
    u.type === "category" && t(u), ye(u);
  }
  function V(u) {
    ve(u), u.uid === "root-mobile" && t(void 0);
  }
  function xe(u) {
    u.type === "category" && t(u), be(u);
  }
  y(() => {
    v.type === "category" && t(v);
  }, [v, t]), y(() => {
    T.type === "category" && t(T);
  }, [T, t]);
  function U(u) {
    u ? (Ze(), A({
      type: "header",
      data: { user_selected_element: "Menu" }
    })) : en(), g(u);
  }
  return /* @__PURE__ */ n(Ne, { "aria-label": s("MainNavigation"), ref: h, children: /* @__PURE__ */ l(we, { children: [
    /* @__PURE__ */ l(Se, { children: [
      /* @__PURE__ */ n(
        "div",
        {
          className: "contents lg:hidden",
          "data-testid": "header-navigation-mobile",
          children: /* @__PURE__ */ l(
            Be,
            {
              open: m,
              onOpenChange: (u) => U(u),
              modal: !1,
              children: [
                /* @__PURE__ */ n(
                  Ue,
                  {
                    asChild: !0,
                    "data-testid": "header-navigation-button-mobile",
                    children: /* @__PURE__ */ n(Ce, {})
                  }
                ),
                /* @__PURE__ */ l(
                  ze,
                  {
                    container: h.current,
                    forceMount: i,
                    children: [
                      /* @__PURE__ */ n("div", { className: "sr-only", children: /* @__PURE__ */ n(Re, { children: s("Menu") }) }),
                      o ? (
                        /* renders the full category tree (client-side) */
                        /* @__PURE__ */ n(
                          Xe,
                          {
                            onClose: () => U(!1),
                            breadcrumbs: j,
                            categories: [
                              ...o || [],
                              ...G
                            ],
                            lastBreadcrumb: T,
                            onBack: (u) => V(u),
                            onSelect: (u) => W(u)
                          }
                        )
                      ) : (
                        /* renders and loads each category on navigation (old behavior) */
                        /* @__PURE__ */ n(
                          Z,
                          {
                            onClose: () => U(!1),
                            breadcrumbs: j,
                            categories: [
                              ...e || [],
                              ...G
                            ],
                            lastBreadcrumb: T,
                            onBack: (u) => V(u),
                            onSelect: (u) => W(u)
                          }
                        )
                      )
                    ]
                  }
                ),
                m && /* @__PURE__ */ n("div", { className: "fixed inset-0 z-[9999] bg-[hsl(200deg_60%_12%/75%)] motion-safe:animate-fade-in" })
              ]
            }
          )
        }
      ),
      /* @__PURE__ */ n(
        "div",
        {
          className: "hidden lg:contents",
          "data-testid": "header-navigation-desktop",
          children: /* @__PURE__ */ l(
            $,
            {
              open: a,
              onOpenChange: (u) => {
                c(u), u && A({
                  type: "header",
                  data: { user_selected_element: "Menu" }
                });
              },
              children: [
                /* @__PURE__ */ n(
                  q,
                  {
                    "data-testid": "header-navigation-button-desktop",
                    className: d(
                      "h-[46px] rounded-lg",
                      "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                    ),
                    children: /* @__PURE__ */ n(ke, { isOpen: a })
                  }
                ),
                /* @__PURE__ */ n(
                  K,
                  {
                    withBackdrop: !0,
                    container: h.current,
                    align: "start",
                    alignOffset: -121,
                    sideOffset: -10,
                    children: /* @__PURE__ */ n(
                      Z,
                      {
                        onClose: () => c(!1),
                        breadcrumbs: k,
                        categories: e,
                        lastBreadcrumb: v,
                        onBack: b,
                        onSelect: (u) => xe(u)
                      }
                    )
                  }
                )
              ]
            }
          )
        }
      )
    ] }),
    /* @__PURE__ */ n(
      "div",
      {
        className: "hidden lg:contents",
        "data-testid": "header-navigation-desktop-baseitems",
        children: /* @__PURE__ */ n(Ae, {})
      }
    )
  ] }) });
}
const tn = "base-navigation";
function rn() {
  const [e, r] = p(
    void 0
  ), t = x(), i = w(
    () => Q(t),
    [t]
  ), o = X(
    (m) => i.getCategories(m),
    [i]
  ), s = X(async () => e ? o(e.dataUrl) : [], [e, o]), { data: a } = O({
    queryKey: [tn, e == null ? void 0 : e.uid],
    queryFn: s,
    placeholderData: se,
    throwOnError: !1
  });
  function c(m) {
    r(m);
  }
  return { categories: a, onSelect: c };
}
function it({ store: e }) {
  const { categories: r, onSelect: t } = rn();
  return /* @__PURE__ */ n(nn, { store: e, categories: r, onSelect: t });
}
const sn = "base-navigation";
function st(e) {
  const r = x(), t = w(
    () => Q(r),
    [r]
  ), { data: i } = O({
    queryKey: [sn],
    queryFn: async () => (await t.getCategoryTree()).categories,
    placeholderData: se,
    throwOnError: !1,
    enabled: e
  });
  return { categoryTree: i };
}
function ot({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: d(
        "absolute right-0 top-0 flex h-[50px] items-center gap-[10px] pl-[10px] lg:relative lg:h-[46px]",
        e
      ),
      ...t,
      children: r
    }
  );
}
const on = _(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.Account
  }))
), an = _(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.LoyaltyBenefits
  }))
);
function at({
  user: e,
  container: r,
  onLogin: t,
  ...i
}) {
  const { SHOPFRONT_URL: o } = x(), [s, a] = p(!1), [c, m] = p(!1), g = typeof window < "u" && window.location.search.includes("showLoyaltyBenefits");
  document.addEventListener("openAccountDialog", () => {
    a(!0);
  });
  function h() {
    a(!1);
  }
  y(() => {
    const b = document.getElementById("onetrust-consent-sdk");
    b && (b.style.pointerEvents = "auto");
  }, []), y(() => {
    c && a(!0);
  }, [c]), y(() => {
    g && (e != null && e.isLoggedIn) && m(!0);
  }, [g, e]);
  const { isMobile: S } = x(), { t: C } = f("ig-header"), k = S ? oe : ae, v = `${o}/my/`;
  return e != null && e.isLoggedIn ? c ? /* @__PURE__ */ n(
    k,
    {
      trigger: /* @__PURE__ */ n(M, { children: /* @__PURE__ */ n(E, { user: e, href: v }) }),
      open: s,
      onOpenChange: a,
      container: r,
      dialogTitle: C("LoyaltyCard"),
      children: /* @__PURE__ */ n(
        an,
        {
          close: () => h(),
          loyaltyCardNumber: e.defaultLoyaltyCardNumber
        }
      )
    }
  ) : /* @__PURE__ */ n(
    E,
    {
      user: e,
      href: v,
      onClick: () => {
        A({
          type: "header",
          data: { user_selected_element: "My account" }
        });
      }
    }
  ) : /* @__PURE__ */ n(
    k,
    {
      trigger: /* @__PURE__ */ n(M, { asChild: !0, children: /* @__PURE__ */ n(E, { user: e }) }),
      open: s,
      onOpenChange: a,
      container: r,
      dialogTitle: C("Login"),
      children: /* @__PURE__ */ n(F, { children: /* @__PURE__ */ n(
        on,
        {
          ...i,
          setShowLoyaltyBenefits: m,
          onClose: () => h(),
          onLogin: (b) => t(b).then(() => {
            c || h();
          }),
          initLocation: "global_header"
        }
      ) })
    }
  );
}
const ln = _(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.Account
  }))
), cn = _(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.LoyaltyBenefits
  }))
), ne = _(
  () => import("@intergamma/theme-next").then((e) => ({
    default: e.IntergammaTheme
  }))
);
function lt({
  user: e,
  container: r,
  onLogin: t,
  ...i
}) {
  const { SHOPFRONT_URL: o } = x(), [s, a] = p(!1), [c, m] = p(!1), g = Me();
  document.addEventListener("openAccountDialog", () => {
    a(!0);
  });
  function h() {
    a(!1);
  }
  y(() => {
    const b = document.getElementById("onetrust-consent-sdk");
    b && (b.style.pointerEvents = "auto");
  }, []), y(() => {
    c && a(!0);
  }, [c]);
  const { isMobile: S } = x(), { t: C } = f("ig-header"), k = S ? oe : ae, v = `${o}/my/`;
  return e != null && e.isLoggedIn ? c ? /* @__PURE__ */ n(
    k,
    {
      trigger: /* @__PURE__ */ n(M, { children: /* @__PURE__ */ n(E, { user: e, href: v }) }),
      open: s,
      onOpenChange: a,
      container: r,
      dialogTitle: C("LoyaltyCard"),
      children: /* @__PURE__ */ n(ne, { formula: g, children: /* @__PURE__ */ n(
        cn,
        {
          close: () => h(),
          loyaltyCardNumber: e.defaultLoyaltyCardNumber
        }
      ) })
    }
  ) : /* @__PURE__ */ n(E, { user: e, href: v }) : /* @__PURE__ */ n(
    k,
    {
      trigger: /* @__PURE__ */ n(M, { asChild: !0, children: /* @__PURE__ */ n(E, { user: e }) }),
      open: s,
      onOpenChange: a,
      container: r,
      dialogTitle: C("Login"),
      children: /* @__PURE__ */ n(F, { children: /* @__PURE__ */ n(ne, { formula: g, children: /* @__PURE__ */ n(
        ln,
        {
          ...i,
          setShowLoyaltyBenefits: m,
          onClose: () => h(),
          onLogin: (b) => t(b).then(() => {
            c || h();
          }),
          initLocation: "global_header"
        }
      ) }) })
    }
  );
}
function ct() {
  return /* @__PURE__ */ n(te, { children: /* @__PURE__ */ n(P, {}) });
}
function ut(e) {
  return /* @__PURE__ */ n(te, { children: /* @__PURE__ */ n(qe, { ...e }) });
}
const un = ({ show: e }) => /* @__PURE__ */ n(
  "div",
  {
    className: d(
      "fixed inset-0 z-[-100] size-full bg-black opacity-60 transition-opacity duration-[600ms] ease-in-out",
      e && "z-[9950] block",
      !e && "hidden"
    )
  }
), dn = ({
  show: e
}) => /* @__PURE__ */ n(
  "div",
  {
    className: d(
      "fixed inset-0 z-[-100] size-full bg-white opacity-0 transition-opacity duration-[600ms] ease-in-out",
      e && "z-[9950] opacity-100"
    )
  }
), mn = ({
  isSearching: e,
  children: r
}) => /* @__PURE__ */ n(
  "div",
  {
    className: d(
      "absolute left-[-10px] flex w-[calc(100%+20px)] pb-[12px] pl-[110px] pr-[10px] transition-all duration-[400ms] ease-in-out",
      "top-[59px] karwei:top-[65px]",
      e && "top-[10px] z-[9951] pl-[10px] pt-[10px]",
      "lg:bottom-[-12px] lg:top-auto"
    ),
    children: r
  }
);
function I(e, r) {
  return new Promise((t) => {
    const i = e.querySelector(r);
    if (i) {
      t(i);
      return;
    }
    new MutationObserver((o, s) => {
      Array.from(document.querySelectorAll(r)).forEach((a) => {
        t(a), s.disconnect();
      });
    }).observe(e, {
      childList: !0,
      subtree: !0
    });
  });
}
const gn = ({
  searching: e,
  children: r
}) => /* @__PURE__ */ n(
  "div",
  {
    className: d(
      "w-full",
      "md:ease md:absolute md:inset-x-auto md:top-[57px] md:z-[11] md:w-[200px] md:transition-[right] md:duration-100",
      e && "md:z-[9951]",
      "lg:relative lg:right-auto lg:top-0 lg:ml-8",
      "xl:w-[376px]"
    ),
    children: r
  }
);
function dt() {
  const [e, r] = p(!1), t = R(null);
  return y(() => {
    const i = t.current, o = () => r(!0), s = () => r(!1);
    return i && I(i, "input[type=search]").then((a) => {
      a.addEventListener("focus", o), a.addEventListener("blur", s);
    }), () => {
      i && I(i, "input[type=search]").then((a) => {
        a.removeEventListener("focus", o), a.removeEventListener("blur", s);
      });
    };
  }, [t]), /* @__PURE__ */ l("div", { className: "contents", ref: t, children: [
    /* @__PURE__ */ n(gn, { searching: e, children: /* @__PURE__ */ n(P, {}) }),
    /* @__PURE__ */ n(un, { show: e })
  ] });
}
const fn = ({
  $isSearching: e,
  onClick: r,
  children: t
}) => /* @__PURE__ */ n(
  "button",
  {
    onClick: r,
    className: d(
      "close-search-mask",
      e ? "flex" : "hidden",
      "ml-2.5 size-[46px] flex-[0_0_46px] flex-col items-center pt-[11px] karwei:pt-[9px]"
    ),
    children: t
  }
), hn = () => /* @__PURE__ */ l(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    children: [
      /* @__PURE__ */ n("line", { x1: "18", y1: "6", x2: "6", y2: "18" }),
      /* @__PURE__ */ n("line", { x1: "6", y1: "6", x2: "18", y2: "18" })
    ]
  }
);
function mt() {
  const [e, r] = p(!1), t = R(null);
  return y(() => {
    const o = t.current, s = () => r(!0);
    return o && I(o, "input[type=search]").then((a) => {
      a.addEventListener("focus", s);
    }), () => {
      o && I(o, "input[type=search]").then((a) => {
        a.removeEventListener("focus", s);
      });
    };
  }, [t]), /* @__PURE__ */ l("div", { className: "contents", ref: t, children: [
    /* @__PURE__ */ l(mn, { isSearching: e, children: [
      /* @__PURE__ */ n(P, {}),
      /* @__PURE__ */ n(fn, { $isSearching: e, onClick: () => {
        r(!1);
      }, children: /* @__PURE__ */ n(hn, {}) })
    ] }),
    /* @__PURE__ */ n(dn, { show: e })
  ] });
}
function ge() {
  return /* @__PURE__ */ n(Fe, { className: "pointer-events-none", size: 16 });
}
function fe() {
  return /* @__PURE__ */ n(Ye, { className: "pointer-events-none", size: 16 });
}
function gt({
  alternateURL: e,
  container: r
}) {
  const { t } = f("ig-header"), [i, o] = p(!1);
  return Y().name !== "gamma_be" ? null : /* @__PURE__ */ l(
    $,
    {
      open: i,
      onOpenChange: (a) => {
        o(a), a && A({
          type: "header",
          data: {
            user_selected_element: "Taal"
          }
        });
      },
      children: [
        /* @__PURE__ */ n(q, { asChild: !0, children: /* @__PURE__ */ l(
          "button",
          {
            className: d(
              re,
              "h-auto cursor-pointer border-none bg-none"
            ),
            children: [
              /* @__PURE__ */ n("strong", { children: t("Language") }),
              i ? /* @__PURE__ */ n(fe, {}) : /* @__PURE__ */ n(ge, {})
            ]
          }
        ) }),
        /* @__PURE__ */ n(
          K,
          {
            className: "!z-[99999] !w-max",
            align: "end",
            onOpenAutoFocus: pn,
            container: r,
            children: /* @__PURE__ */ n("div", { className: "p-5 text-75/normal", children: /* @__PURE__ */ l("ul", { className: "m-0 list-none p-0", children: [
              /* @__PURE__ */ n(L, { children: /* @__PURE__ */ n(
                N,
                {
                  className: "whitespace-nowrap no-underline hover:underline",
                  lang: "nl",
                  href: (e == null ? void 0 : e.nl) ?? "/nl",
                  onClick: () => {
                    A({
                      type: "header",
                      data: {
                        user_selected_element: "Taal",
                        user_selected_value: "NL"
                      }
                    });
                  },
                  children: "Nederlands"
                }
              ) }),
              /* @__PURE__ */ n(L, { children: /* @__PURE__ */ n(
                N,
                {
                  className: "whitespace-nowrap no-underline hover:underline",
                  lang: "fr",
                  href: (e == null ? void 0 : e.fr) ?? "/fr",
                  onClick: () => {
                    A({
                      type: "header",
                      data: {
                        user_selected_element: "Taal",
                        user_selected_value: "FR"
                      }
                    });
                  },
                  children: "Français"
                }
              ) })
            ] }) })
          }
        )
      ]
    }
  );
}
function pn(e) {
  const r = e.target;
  if (!r)
    return;
  const t = r.querySelector("a");
  t && (e.preventDefault(), t.focus());
}
function H(e) {
  const r = D(e, {
    withCredentials: !0
  });
  return {
    getUser() {
      return r.get("/api/session-data");
    },
    getNearbyStores(t) {
      return r.get(
        `/api/session-data/nearby-stores/${t.uid}`
      );
    },
    async changeStore(t) {
      return r.put(
        `/api/session-data/preferred-store/${t.uid}`
      );
    },
    async logout() {
      const t = await this.getGatewayCSRF();
      return r.post("/gateway/logout", null, {
        headers: {
          "X-IG-CSRF-TOKEN": t
        }
      });
    },
    async login(t) {
      const i = await this.getGatewayCSRF();
      return r.post(
        "/gateway/addcard/login",
        t,
        {
          headers: {
            "X-IG-CSRF-TOKEN": i
          }
        }
      );
    },
    async getGatewayCSRF() {
      return (await r.get("/gateway/session")).csrfToken;
    }
  };
}
function ft() {
  const { MYACCOUNT_URL: e } = x(), r = Ie(), t = w(
    () => H(e),
    [e]
  );
  function i(o) {
    return t.changeStore(o).then(() => {
      document == null || document.body.dispatchEvent(
        new window.CustomEvent("refreshStore", { detail: o })
      ), A({
        type: "change_store",
        data: {
          user_selected_value: o
        }
      });
    });
  }
  return De({
    mutationFn: i,
    throwOnError: !1,
    onSuccess: () => {
      r.invalidateQueries();
    }
  });
}
const bn = "nearby-store";
function ht(e) {
  const { MYACCOUNT_URL: r } = x(), t = w(
    () => H(r),
    [r]
  );
  function i() {
    return e ? t.getNearbyStores(e) : [];
  }
  return O({
    queryKey: [bn, e],
    queryFn: i,
    throwOnError: !1
  });
}
function he({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: d(
        "rounded-b-lg p-5 gamma:bg-[#f0f2f6] karwei:bg-[#f1f4f8]",
        e
      ),
      ...t,
      children: r
    }
  );
}
function pe({
  children: e,
  className: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "h3",
    {
      className: d(
        "mb-5 text-[1.2em] font-bold text-brand-primary",
        r
      ),
      ...t,
      children: e
    }
  );
}
function vn() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ l(he, { children: [
    /* @__PURE__ */ n(pe, { children: e("OtherStoresNearby") }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ l("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(L, { children: /* @__PURE__ */ n(N, { href: e("/stores/search"), children: e("AllStores") }) }),
      /* @__PURE__ */ n(L, { children: /* @__PURE__ */ n(N, { href: e("/stores/holidays"), children: e("SpecialOpeningHours") }) })
    ] })
  ] });
}
function yn({ nearbyStore: e, onSaveStore: r }) {
  const { t } = f("ig-header");
  return /* @__PURE__ */ l("div", { className: "mb-5 grid grid-cols-2 last:mb-0", children: [
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ n(
      "a",
      {
        className: "font-bold text-black no-underline hover:underline",
        href: t("/stores/details", e),
        children: e.name
      }
    ) }),
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ l(
      "button",
      {
        className: "group flex h-auto cursor-pointer items-center border-none bg-transparent p-0 text-[1em] hover:underline",
        onClick: () => r(e),
        children: [
          /* @__PURE__ */ n($e, { className: "mr-[5px] rounded-[20px] bg-white !p-[5px] group-hover:fill-current gamma:text-brand-primary karwei:text-[#111316]" }),
          t("SaveAsMyStore")
        ]
      }
    ) })
  ] });
}
function xn({ nearbyStores: e, onSaveStore: r }) {
  const { t } = f("ig-header");
  return /* @__PURE__ */ l(he, { children: [
    /* @__PURE__ */ n(pe, { children: t("OtherStoresNearby") }),
    e.map((i) => /* @__PURE__ */ n(
      yn,
      {
        nearbyStore: i,
        onSaveStore: r
      },
      i.uid
    )),
    /* @__PURE__ */ l("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(L, { children: /* @__PURE__ */ n(N, { href: t("/stores/search"), children: t("AllStores") }) }),
      /* @__PURE__ */ n(L, { children: /* @__PURE__ */ n(N, { href: t("/stores/holidays"), children: t("SpecialOpeningHours") }) })
    ] })
  ] });
}
const Nn = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
];
function wn({ store: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ l("div", { className: "p-5", children: [
    /* @__PURE__ */ n("h2", { className: "mb-5 text-[1.3em] font-bold text-brand-primary", children: e.name }),
    /* @__PURE__ */ l("div", { className: "mb-5 flex last:mb-0", children: [
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: /* @__PURE__ */ l("div", { className: "leading-normal", children: [
        e.address.street,
        " ",
        e.address.streetNumber,
        e.address.apartment ? `/${e.address.apartment}` : null,
        /* @__PURE__ */ n("br", {}),
        e.address.zipCode,
        " ",
        e.address.city,
        /* @__PURE__ */ n("br", {}),
        e.address.phone,
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n(N, { href: r("/stores/details", { slug: e.slug }), children: r("ShowOnMap") })
      ] }) }),
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: e.openingHours.map((t) => /* @__PURE__ */ l("div", { className: "mb-5 flex last:mb-0", children: [
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: r(Nn[t.dayOfWeek]) }),
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: t.opens && t.closes ? r("Timespan", { opens: t.opens, closes: t.closes }) : r("Closed") })
      ] }, t.dayOfWeek)) })
    ] })
  ] });
}
function Sn({
  store: e,
  isLoadingNearbyStores: r,
  ...t
}) {
  return /* @__PURE__ */ l("div", { className: "w-[500px] font-primary text-75/normal tabular-nums", children: [
    /* @__PURE__ */ n(wn, { store: e }),
    r ? /* @__PURE__ */ n(vn, {}) : /* @__PURE__ */ n(xn, { ...t })
  ] });
}
function Cn() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ n(ie, { href: e("/stores/search"), children: /* @__PURE__ */ n("strong", { children: e("Stores") }) });
}
function pt({
  store: e,
  isLoadingNearbyStores: r,
  container: t,
  ...i
}) {
  const [o, s] = p(!1);
  return e ? /* @__PURE__ */ l($, { open: o, onOpenChange: s, children: [
    /* @__PURE__ */ l(q, { className: re, children: [
      /* @__PURE__ */ n("strong", { children: e.name }),
      o ? /* @__PURE__ */ n(fe, {}) : /* @__PURE__ */ n(ge, {})
    ] }),
    /* @__PURE__ */ n(
      K,
      {
        className: "z-[999999] !max-w-[500px]",
        align: "end",
        container: t,
        children: /* @__PURE__ */ n(
          Sn,
          {
            ...i,
            store: e,
            isLoadingNearbyStores: r
          }
        )
      }
    )
  ] }) : /* @__PURE__ */ n(Cn, {});
}
function bt() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ n(ie, { href: e("/stores/search"), children: /* @__PURE__ */ n(Oe, { className: "w-[120px]" }) });
}
function vt({ children: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ n(
    "nav",
    {
      className: d(
        "absolute right-[220px] top-0 hidden h-[46px] items-center",
        "lg:right-0 lg:flex lg:h-[46px]"
      ),
      "aria-label": r("UserNavigation"),
      children: /* @__PURE__ */ n("ul", { className: "m-0 flex list-none items-center gap-[25px] p-0", children: e && Le.map(e, (t) => _e(t) ? /* @__PURE__ */ n("li", { children: Te(t) }) : null) })
    }
  );
}
const kn = _(
  () => import("./Alert-CxevVYNO.js").then((e) => ({
    default: e.Alert
  }))
);
function An({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(F, { fallback: null, children: e.map(({ sys: t, fields: i }) => /* @__PURE__ */ n(
    kn,
    {
      label: i.description,
      id: t.id,
      onDismissAlert: r
    },
    t.id
  )) });
}
function On({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(Ee, { children: /* @__PURE__ */ n(An, { alerts: e, onDismissAlert: r }) });
}
const z = "disabledAlerts";
function En() {
  if (typeof sessionStorage > "u")
    return [];
  const e = sessionStorage.getItem(z);
  if (!e)
    return [];
  try {
    return JSON.parse(e);
  } catch {
    return [];
  }
}
function Ln(e) {
  if (typeof sessionStorage > "u")
    return;
  const r = sessionStorage.getItem(z) || "[]";
  try {
    const t = JSON.parse(r);
    t.includes(e) || (t.push(e), sessionStorage.setItem(z, JSON.stringify(t)));
  } catch {
  }
}
const _n = D("https://cdn.contentful.com/spaces/");
function Tn({
  contentfulSpaceId: e,
  contentfulToken: r,
  contentfulLocale: t
}) {
  const i = "alert", o = r;
  return _n.get(
    `${e}/environments/master/entries?content_type=${i}&access_token=${o}&locale=${t}`
  ).then((s) => {
    var a;
    return ((a = s == null ? void 0 : s.items) == null ? void 0 : a.length) > 0 ? s.items : [];
  });
}
const Mn = "alerts";
function In() {
  var m;
  const [e, r] = p(En), { contentfulSpaceId: t, contentfulToken: i } = Y(), { i18n: o } = f(), s = o.language.substring(0, 2) === "fr" ? "fr" : "nl", a = O({
    queryKey: [Mn],
    queryFn: () => Tn({
      contentfulLocale: s,
      contentfulSpaceId: t,
      contentfulToken: i
    }),
    throwOnError: !1
  });
  function c(g) {
    Ln(g), r([...e, g]);
  }
  return {
    alerts: ((m = a.data) == null ? void 0 : m.filter(
      (g) => !e.includes(g.sys.id)
    )) ?? [],
    onDismissAlert: c
  };
}
function yt() {
  const { alerts: e, onDismissAlert: r } = In();
  return /* @__PURE__ */ n(On, { alerts: e, onDismissAlert: r });
}
function Dn(e) {
  const r = D(e, {
    withCredentials: !0
  });
  return {
    async getNumberOfProducts() {
      const t = await r.get(
        "/resources/cart/numberOfProducts"
      );
      return Number.isNaN(t) ? 0 : t;
    }
  };
}
const Bn = "number-of-products";
function xt() {
  const { CHECKOUT_URL: e } = x(), r = w(
    () => Dn(e),
    [e]
  );
  function t() {
    return r.getNumberOfProducts();
  }
  return O({
    queryKey: [Bn],
    queryFn: t,
    throwOnError: !1
  });
}
const Un = "wishlist";
function zn() {
  var r;
  if (typeof J > "u")
    return 0;
  const e = J.getItem(Un);
  return e ? (r = JSON.parse(e)) == null ? void 0 : r.length : 0;
}
const Rn = "number-of-favorites";
function Nt() {
  return O({
    queryKey: [Rn],
    queryFn: zn,
    throwOnError: !1,
    initialData: 0
  });
}
const Fn = "user";
function wt() {
  const { MYACCOUNT_URL: e } = x(), r = w(
    () => H(e),
    [e]
  );
  return O({
    queryKey: [Fn],
    queryFn: r.getUser,
    throwOnError: !1
  });
}
function St(e) {
  return Q(e).getCategories;
}
export {
  Mn as ALERTS_QUERY_KEY,
  tn as BASE_NAVIGATION_QUERY_KEY,
  sn as BASE_NAVIGATION_TREE_QUERY_KEY,
  nn as BaseNavigation,
  At as CartIconNavigation,
  Ot as FavoritesIconNavigation,
  On as HeaderAlerts,
  Et as HeaderContainer,
  Lt as IconsNavigation,
  ot as IconsNavigationContainer,
  gt as LanguageSelector,
  _t as LoadingBaseNavigation,
  Tt as LoadingHeader,
  Mt as LoadingIconsNavigation,
  It as LoadingSearch,
  bt as LoadingStores,
  Dt as LoadingTopNavigation,
  it as MainBaseNavigation,
  yt as MainHeaderAlerts,
  ct as MainSearch,
  bn as NEARBY_STORES_QUERY_KEY,
  Rn as NUMBER_OF_FAVORITES_QUERY_KEY,
  ut as Search,
  te as SearchContainer,
  un as ShopfrontPageMask,
  dn as ShopfrontSearchBarMask,
  mn as ShopfrontSearchContainer,
  dt as ShopfrontSearchDesktop,
  mt as ShopfrontSearchMobile,
  pt as StoreLocatorDropdown,
  lt as StyledUserIconNavigation,
  vt as TopNavigation,
  Fn as USER_QUERY_KEY,
  at as UserIconNavigation,
  E as UserIconNavigationButton,
  St as createGetAssortimentCategories,
  Ze as disablePageScroll,
  en as enablePageScroll,
  Tn as getAlerts,
  En as getSessionDisabledAlert,
  Ln as setSessionDisabledAlert,
  In as useAlertsQuery,
  rn as useCategoriesQuery,
  st as useCategoryTreeQuery,
  ft as useChangeStoreMutation,
  ht as useNearbyStoresQuery,
  Nt as useNumberOfFavorites,
  xt as useNumberOfProductsQuery,
  Ut as useTranslation,
  wt as useUserQuery
};
