import { HTMLInputTypeAttribute, ReactNode } from "react";

import { cn } from "@intergamma/common/cn";

type InputStylesOptions = {
  error?: ReactNode;
  type?: HTMLInputTypeAttribute;
};

export function inputStyles({ error, type }: InputStylesOptions) {
  return cn(
    "mt-0.5 block h-12 w-full border bg-white px-4 text-100/6 outline-none transition-colors gamma:rounded-md gamma:border-ignew-neutral-200 gamma:caret-brand-conversion karwei:rounded-none karwei:border-ignew-neutral-100 karwei:caret-brand-primary",
    "gamma:focus:border-ignew-functional-primary-800 karwei:focus:border-ignew-neutral-1000",
    "hover:bg-ignew-neutral-25",
    "gamma:disabled:!border-ignew-neutral-100 gamma:disabled:bg-ignew-neutral-50 gamma:disabled:text-ignew-neutral-400 karwei:disabled:!border-transparent karwei:disabled:bg-ignew-neutral-100 karwei:disabled:text-ignew-neutral-600",
    "gamma:placeholder:text-ignew-neutral-400 karwei:placeholder:text-ignew-neutral-400",
    type === "number" &&
      "[-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none",
    error &&
      "gamma:!border-ignew-functional-quaternary-700 karwei:!border-ignew-functional-quaternary-600",
  );
}
