import { datadogLogs as o } from "@datadog/browser-logs";
import { c as i } from "./index-C2EW4nef.js";
const c = o.logger;
function u(s) {
  const r = i(`${s}/api`, {
    withCredentials: !0
  });
  return {
    getAccountState(e) {
      return r.get(
        `/account/?email=${encodeURIComponent(e)}`
      );
    },
    resendVerification(e) {
      return r.post(
        "/signup/resendsignupverification",
        e
      );
    },
    signUp(e) {
      return r.post("/signup", e);
    },
    verify(e, t, n) {
      return r.post(`/verify/${e}?hash=${t}`, {
        hash: t,
        customerId: n
      });
    },
    passwordReset(e) {
      return r.post("/password-reset", e);
    },
    verifyPasswordReset(e) {
      return r.post("/verify/password-reset", e);
    },
    preferences(e) {
      return r.put("/preferences", { loginMethod: e });
    }
  };
}
export {
  u as c,
  c as l
};
