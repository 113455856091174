import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export const Info = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  // eslint-disable-next-line prefer-arrow-callback
  function Info({ className, ...props }, ref) {
    return (
      <>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={ref}
          className={cn("karwei:hidden", className)}
          {...props}
        >
          <path
            d="M12 17.296C11.5582 17.296 11.2 16.9378 11.2 16.496V10.496C11.2 10.0542 11.5582 9.69599 12 9.69599C12.4419 9.69599 12.8 10.0542 12.8 10.496V16.496C12.8 16.9378 12.4419 17.296 12 17.296Z"
            fill="currentColor"
          />
          <path
            d="M11.2 7.496C11.2 7.93783 11.5582 8.296 12 8.296C12.4418 8.296 12.8 7.93783 12.8 7.496V7.4C12.8 6.95817 12.4418 6.6 12 6.6C11.5582 6.6 11.2 6.95817 11.2 7.4V7.496Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75Z"
            fill="currentColor"
          />
        </svg>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={ref}
          className="gamma:hidden"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM2.90909 12C2.90909 6.97923 6.97923 2.90909 12 2.90909C17.0208 2.90909 21.0909 6.97923 21.0909 12C21.0909 17.0208 17.0208 21.0909 12 21.0909C6.97923 21.0909 2.90909 17.0208 2.90909 12Z"
            fill="currentColor"
          />
          <path
            d="M11.2578 7.25H12.7559V8.7758H11.2578V7.25Z"
            fill="currentColor"
          />
          <path
            d="M11.2441 16.746V10.7382H12.7559V16.746H11.2441Z"
            fill="currentColor"
          />
        </svg>
      </>
    );
  },
);
