function o(a, e) {
  if (a.length < 2)
    throw new Error(`Could not get allocation for ${a}`);
  const r = 1 / a.length * e * 100, n = 1 / a.length * (e + 1) * 100;
  return [r, n];
}
function t(a) {
  const e = {};
  if (!a.includes("A-control"))
    throw Error("No control group found with name 'A-control'");
  for (const [r, n] of a.entries())
    e[n] = {
      allocation: o(a, r)
    };
  return e;
}
const _ = {
  // CHECKOUT ------------------------------------------------------------------
  "abtest_dba_3240-dba_1": {
    description: "Improved date picker",
    variants: t(["A-control", "B-new"]),
    domain_and_index: "abtest_name_dba_1",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3345-dba_2": {
    description: "Position of block titles",
    variants: t(["A-control", "B-new"]),
    domain_and_index: "abtest_name_dba_2",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3297-dba_7": {
    description: "Updated cart tiles retest",
    variants: t(["A-control", "B-price", "C-next"]),
    domain_and_index: "abtest_name_dba_7",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba-3298-dba_3": {
    description: "Free pickup or delivery in cart summary",
    variants: t(["A-control", "B-promo", "C-green"]),
    domain_and_index: "abtest_name_dba_3",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3352-dba_4": {
    description: "Empty cart state",
    variants: t(["A-control", "B-improved"]),
    domain_and_index: "abtest_name_dba_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // MYACCOUNT -----------------------------------------------------------------
  "abtest_drl_3327-drl_1": {
    description: "Passwordless signup as default",
    variants: t(["A-control", "B-default", "C-explanation"]),
    domain_and_index: "abtest_name_drl_1",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // SHOPFRONT -----------------------------------------------------------------
  "abtest_xtd_autosug-ew_4": {
    description: "Extend autosuggest/autocomplete with categories filter",
    variants: t(["A-control", "B-six-sug", "C-incl-cat"]),
    domain_and_index: "abtest_name_ew_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  }
};
export {
  _ as experiments
};
