import { media as o } from "@intergamma/theme";
import { css as a, createGlobalStyle as i } from "styled-components";
import { IntergammaToastContainer as m } from "./toast-container.js";
const n = i`
	:root, :host {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #3E802A;
    --toastify-color-warning: #fff2de;
    --toastify-color-error: #b93a16;

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: #975e08;
    --toastify-icon-color-error: var(--toastify-color-error);


    --toastify-toast-width: 100%;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 40px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: ${({ theme: t }) => t.fonts[0].family};
    --toastify-z-index: 9999;

    --toastify-text-color-light: ${({ theme: t }) => t.colors.black};
    --toastify-text-color-dark: #fff;

    // Used only for colored theme
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: ${({ theme: t }) => t.colors.white};
    --toastify-text-color-warning: #975e08;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    // Used when no type is provided
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
  }

  .Toastify__toast-container {
    top: auto;
    pointer-events: all;
    bottom: 0.5rem;
    right: auto;
    left: 0.5rem;
    width: calc(100% - 1rem);
    padding: 0 8px;
    z-index: 100000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    ${({ $sticky: t, headerHeight: s }) => t === "xs" && a`
        position: absolute;
        top: calc(${s ?? 125}px + 1rem);
        bottom: auto;
      `}

    ${o.md`
      top: calc(${(t) => t.headerHeight ?? 125}px + 1rem);
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      max-width: 768px;
      padding: 0 24px;
    `};

    ${o.lg`
      max-width: 1024px;
      padding: 0 32px;
    `};

    ${o.xl`
      max-width: 1264px;
      padding: 0 32px;
    `};
  }

  .Toastify__toast-body {
    align-items: stretch;
  }

  .closeButton {
    margin-top: 0.25rem;
    background: transparent;
    border: none;
  }

  .closeButton svg {
    width: 19px;
  }

  .Toastify__toast {
    border-radius: 6px;
  }

  .Toastify__toast-body {
    padding-right: 1.25rem;
  }

  .Toastify__toast-icon {
    width: 24px;
    height: 24px;
    align-items: flex-start;
  }

  @keyframes intergamma-toast-fwd-desktop {
    0% {
      transform: translateY(-30px);
      opacity: 0;
    }

    70% {
      transform: translateY(0);
      opacity: 0.5;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes intergamma-toast-fwd-mobile {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }

    70% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .intergamma-toast-fwd {
    animation: intergamma-toast-fwd-mobile 0.4s ease-in-out both;

    ${o.md`
      animation: intergamma-toast-fwd-desktop 0.4s ease-in-out both;
    `}

    ${({ $sticky: t }) => t === "xs" && a`
        animation: intergamma-toast-fwd-desktop 0.4s ease-in-out both;
      `}
  }

  @keyframes intergamma-toast-bck-desktop {
    0% {
      transform: translateY(0);
      opacity: 1;
    }


    100% {
      transform: translateY(-30px);
      opacity: 0;
    }
  }

  @keyframes intergamma-toast-bck-mobile {
    0% {
      transform: translateY(0);
      opacity: 1;
    }

    70% {
      transform: translateY(100px);
      opacity: 0.7;
    }

    100% {
      transform: translateY(100px);
      opacity: 0;
    }
  }

  .intergamma-toast-bck {
    animation: intergamma-toast-bck-mobile 0.4s ease-in-out both;

    ${o.md`
      animation: intergamma-toast-bck-desktop 0.4s ease-in-out both;
    `}

    ${({ $sticky: t }) => t === "xs" && a`
        animation: intergamma-toast-bck-desktop 0.4s ease-in-out both;
      `}
  }

  ${({ $sticky: t }) => t === "md" && a`
      ${o.md`
        .Toastify {
          position: sticky;
          top: 0;
          z-index: var(--toastify-z-index);
        }
      `}
    `}

    ${({ $sticky: t }) => t === "xs" && a`
        .Toastify {
          position: sticky;
          top: 0;
          z-index: var(--toastify-z-index);
        }
      `}

  .Toastify__toast-theme--colored.Toastify__toast--warning .Toastify__close-button  {
    color: var(--toastify-icon-color-warning);
  }
`;
export {
  n as GlobalToastStyling,
  m as IntergammaToastContainer
};
