import { isMobileApp } from "@intergamma/common/device"
import { LegalFooter } from "@intergamma/legal-footer"
import { formulaConfig } from "config/formula"
import { Suspense } from "react"
import { useTranslation } from "react-i18next"

export function Footer() {
  if (isMobileApp) {
    return null
  }

  return (
    <footer>
      <Suspense fallback={null}>
        <FooterInner />
      </Suspense>
    </footer>
  )
}

// This is a separate component, because we need to wrap `useTranslation` in a
// Suspense boundary.
function FooterInner() {
  const { t, i18n } = useTranslation(["ig-footer", "ig-legal-footer"])

  return <LegalFooter formula={formulaConfig} withSocials={false} withAppPromotion={false} t={t} lang={i18n.language} />
}
