import { forwardRef, type ComponentProps } from "react";

export const Warning = forwardRef<SVGSVGElement, ComponentProps<"svg">>(
  (props, ref) => (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0203 16.9057C26.0203 16.022 25.304 15.3057 24.4203 15.3057C23.5367 15.3057 22.8203 16.022 22.8203 16.9057V28.9057C22.8203 29.7893 23.5367 30.5057 24.4203 30.5057C25.304 30.5057 26.0203 29.7893 26.0203 28.9057V16.9057ZM26.1805 34.1925C26.1805 33.3088 25.4641 32.5925 24.5805 32.5925C23.6968 32.5925 22.9805 33.3088 22.9805 34.1925V34.3845C22.9805 35.2681 23.6968 35.9845 24.5805 35.9845C25.4641 35.9845 26.1805 35.2681 26.1805 34.3845V34.1925Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4999 8.69043L7.13738 38.7632H41.8624L24.4999 8.69043ZM26.232 5.69043C25.4622 4.3571 23.5377 4.3571 22.7679 5.69043L3.67328 38.7632C2.90348 40.0965 3.86573 41.7632 5.40533 41.7632H43.5945C45.1341 41.7632 46.0963 40.0965 45.3265 38.7632L26.232 5.69043Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(Warning as React.FC).displayName = "Warning";
