import type { AdaptedShipment, CartSummaryData, ShipmentResponse } from "features/checkout/types"
import { arrayToDate } from "lib/calendar"

function findProductById(id: string, cartSummaryData: CartSummaryData | undefined) {
  return cartSummaryData?.entries?.find((entry) => entry.productId === id)
}

export function adaptShipmentsData(
  shipments: ShipmentResponse,
  cartSummaryData?: CartSummaryData | undefined
): AdaptedShipment[] {
  return shipments
    .map(
      ({ id, carrierCode, entries, deliveryMode, deliveryPromise, availableShifts, defaultShift, shipmentTypes }) => ({
        id,
        carrierCode,
        deliveryMode,
        deliveryPromise,
        defaultShiftDate: defaultShift && arrayToDate(defaultShift.date),
        availableShifts: availableShifts.map((shift) => ({ ...shift, date: arrayToDate(shift.date) })),
        entries: entries.map((shipmentEntry) => ({
          sku: shipmentEntry.productId,
          quantity: shipmentEntry.quantity,
          name: findProductById(shipmentEntry.productId, cartSummaryData)?.productName,
          image: findProductById(shipmentEntry.productId, cartSummaryData)?.productImage,
        })),
        shipmentTypes,
      })
    )
    .sort((a, b) => {
      // first shipment first
      const shiftA = a.defaultShiftDate || a.availableShifts[0]?.date
      const shiftB = b.defaultShiftDate || b.availableShifts[0]?.date

      if (!shiftA && shiftB) {
        return 1
      }

      if (shiftA && !shiftB) {
        return -1
      }

      if (!shiftA && !shiftB) {
        return 0
      }

      return shiftA.valueOf() - shiftB.valueOf()
    })
}
