var m = (i) => {
  throw TypeError(i);
};
var p = (i, t, r) => t.has(i) || m("Cannot " + r);
var o = (i, t, r) => (p(i, t, "read from private field"), r ? r.call(i) : t.get(i)), f = (i, t, r) => t.has(i) ? m("Cannot add the same private member more than once") : t instanceof WeakSet ? t.add(i) : t.set(i, r), u = (i, t, r, n) => (p(i, t, "write to private field"), n ? n.call(i, r) : t.set(i, r), r);
import h from "js-cookie";
import { experiments as d } from "./config.js";
var a, e, c;
class k {
  constructor() {
    f(this, a, !1);
    f(this, e);
    f(this, c);
  }
  configure(t) {
    u(this, a, t.dev), u(this, e, t.formula), u(this, c, t.onCoinflip);
  }
  /**
   * Get active experiments with their chosen variant.
   */
  get active() {
    return Object.entries(d).reduce((t, [r, n]) => {
      const s = h.get(r) ?? "";
      return s in n.variants ? {
        ...t,
        [r]: {
          domain_and_index: n.domain_and_index,
          variant: s
        }
      } : t;
    }, {});
  }
  getVariant(t) {
    const r = d[t], n = h.get(t) ?? "";
    if (!r)
      throw Error(
        `Could not find experiment "${t}". Make sure to run \`pnpm run build:packages\` if this is a new experiment.`
      );
    return o(this, e) && !r.formula[o(this, e).id] ? "A-control" : Object.keys(r.variants).includes(n) ? n : void 0;
  }
  setVariant(t, r) {
    var n;
    o(this, e) && (h.set(t, r, {
      expires: 35,
      domain: o(this, e).baseDomain,
      sameSite: "strict"
    }), (n = o(this, c)) == null || n.call(
      this,
      t,
      d[t],
      r
    ));
  }
  coinflip(t) {
    const r = d[t], n = Object.keys(r.variants).find(
      (l) => l.startsWith("A-")
    );
    if (o(this, a) && n)
      return n;
    const s = Math.random() * 100;
    for (const [l, v] of Object.entries(r.variants)) {
      const [b, g] = v.allocation;
      if (s >= b && s < g)
        return l;
    }
    throw new Error(`Failed choosing random variant for "${t}"`);
  }
}
a = new WeakMap(), e = new WeakMap(), c = new WeakMap();
const C = new k();
export {
  C as experiments
};
