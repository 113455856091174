import { jsx as e, jsxs as i } from "react/jsx-runtime";
import { forwardRef as r } from "react";
import { cn as l } from "@intergamma/common/cn";
import * as a from "@radix-ui/react-dialog";
import { DialogRoot as s } from "./DialogRoot.js";
function w({ trigger: t, children: o, ...n }) {
  return /* @__PURE__ */ i(s, { ...n, children: [
    t,
    /* @__PURE__ */ e(c, { children: o })
  ] });
}
function m({
  children: t,
  ...o
}) {
  return /* @__PURE__ */ i(a.Portal, { children: [
    /* @__PURE__ */ e(x, { ...o, children: t }),
    /* @__PURE__ */ e(f, {})
  ] });
}
const f = r(
  (t, o) => /* @__PURE__ */ e(
    a.Overlay,
    {
      ...t,
      className: l(
        "fixed inset-0 z-[9999] bg-[hsla(200,60%,12%,0.75)]",
        "motion-safe:animate-dialog-overlay-show"
      ),
      ref: o
    }
  )
), x = r(({ className: t, ...o }, n) => /* @__PURE__ */ e(
  a.Content,
  {
    ...o,
    className: l(
      "fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
      "z-[99999] rounded-[0.8rem] bg-white",
      "max-h-[85vh] w-[90vw] max-w-[450px]",
      "shadow-[0_10px_38px_-10px_hsla(206,22%,7%,0.35),_0_10px_20px_-15px_hsla(206,22%,7%,0.2)]",
      "focus:outline-none",
      "motion-safe:animate-dialog-content-show-with-blur",
      t
    ),
    ref: n,
    children: o.children
  }
)), c = m;
export {
  w as Dialog,
  c as DialogContent
};
