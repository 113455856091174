import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import { dispatchMobileAppEvent } from "@intergamma/mobile-app"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import { QUERY_KEY as QUERY_KEY_TRACK_VIEW_CART } from "api/tracking"
import { useDispatchTrackCartMutation } from "features/cart/hooks/tracking"
import type { CartMutationResponse, CartResponse, MappedCartResponse } from "features/cart/types"
import { flattenToCart } from "helpers/flattenToCart"
import { mapCart } from "helpers/mapCart"
import { log } from "lib/datadog-logging"
import { useSearchParams } from "react-router"
import type { ViewCartDispatchEventData } from "./generated/checkout"
import { QUERY_KEY_NUMBER_OF_PRODUCTS } from "./number-of-products"

export const QUERY_KEY_CART = "cart"

interface UseCartQueryOptions {
  enabled?: boolean
}
export function useCartQuery({ enabled = true }: UseCartQueryOptions = {}) {
  const [searchParams] = useSearchParams()
  const cartUid = searchParams.get("cartUid") ?? undefined

  return useQuery({
    enabled,
    queryKey: [QUERY_KEY_CART],
    async queryFn({ signal }) {
      let url = "/api/v2/cart"

      if (cartUid) {
        url += `/${cartUid}`
      }

      const { data } = await api.get<CartResponse>(url, { signal })

      return mapCart(data)
    },
  })
}

export function useConnectLoyaltyCardToCartMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn(loyaltyCardNumber: string) {
      log.info("Attaching loyalty card number", { loyaltyCardNumber })
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/loyalty/card-number/${loyaltyCardNumber}`)

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)

      dispatchAdobeEvent({ type: "loyalty_card_added_to_cart", data: { is_loyalty_enabled: "true" } })
    },
  })
}

export function useApplyActionCodeMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn(actionCode: string) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/actioncodes/${actionCode}`)

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
  })
}

export function useRemoveActionCodeMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn(actionCode: string) {
      const { data } = await api.delete<CartMutationResponse>(`/api/v2/cart/actioncodes/${actionCode}`)

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
  })
}

export function useDeleteCartValidationErrorsMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn() {
      return api.delete("/api/v2/cart/validationerrors")
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_CART] })
    },
  })
}

export function useApplyLoyaltyPointsMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn(isEnabled: boolean) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/loyalty/usepoints/${isEnabled}`)

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
  })
}

export function useAddEntryBySkuMutation(sku: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["add-entry", sku],
    async mutationFn(desiredQuantity: number) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/entries/${sku}`, {
        desiredQuantity,
        matchMaxStockIfExceeds: true,
      })

      return mapCart(flattenToCart(data))
    },
    onSuccess(cart) {
      queryClient.setQueryData([QUERY_KEY_CART], cart)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })
    },
  })
}

export function useUpdateEntryQuantityBySkuMutation(sku: string) {
  const queryClient = useQueryClient()
  const trackCartMutation = useDispatchTrackCartMutation()

  return useMutation({
    mutationKey: ["update-entry-quantity", sku],
    async mutationFn(desiredQuantity: number) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/entries/${sku}`, {
        desiredQuantity,
        matchMaxStockIfExceeds: true,
      })

      return mapCart(flattenToCart(data))
    },
    onMutate(desiredQuantity) {
      const oldViewCart = queryClient.getQueryData<ViewCartDispatchEventData>([QUERY_KEY_TRACK_VIEW_CART])
      const oldCart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])!

      queryClient.setQueryData([QUERY_KEY_CART], {
        ...oldCart,
        entries: oldCart.entries.map((entry) => ({
          ...entry,
          quantity: entry.sku === sku ? desiredQuantity : entry.quantity,
        })),
      })

      return { oldCart, oldViewCart }
    },
    onSuccess(cart, desiredQuantity, context) {
      // Update the cache if no other "update-entry-quantity" mutation is in flight.
      if (queryClient.isMutating({ mutationKey: ["update-entry-quantity"] }) <= 1) {
        queryClient.setQueryData([QUERY_KEY_CART], cart)
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })
      }

      const oldEntry = context.oldCart?.entries.find((entry) => entry.sku === sku)
      const updatedEntry = cart.entries.find((entry) => entry.sku === sku)

      if (!oldEntry || !updatedEntry) {
        throw new Error(`Cart entry mutation could not be determined (SKU=${sku})`)
      }

      dispatchMobileAppEvent({
        app: "cart_checkout",
        type: desiredQuantity < oldEntry.quantity ? "IG_REMOVE_FROM_CART" : "IG_ADD_TO_CART",
        payload: {
          state: "success",
          productId: sku,
          amount: updatedEntry.quantity,
          numberOfProducts: cart.cartItemCount,
        },
      })

      if (updatedEntry.quantity < oldEntry.quantity) {
        trackCartMutation("remove_from_cart", {
          quantity: oldEntry.quantity - updatedEntry.quantity,
          sku,
          oldViewCart: context?.oldViewCart,
        })
      }

      if (updatedEntry.quantity > oldEntry.quantity) {
        trackCartMutation("add_to_cart", {
          quantity: updatedEntry.quantity - oldEntry.quantity,
          sku,
          oldViewCart: context?.oldViewCart,
        })
      }
    },
    onError(error, desiredQuantity, context) {
      log.error(`Failed to update cart entry quantity (SKU=${sku})`, {}, error instanceof Error ? error : undefined)

      queryClient.setQueryData([QUERY_KEY_CART], context?.oldCart)

      const cart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])
      const entry = cart?.entries.find((entry) => entry.sku === sku)

      if (!entry) {
        throw new Error(`Cart entry could not be found (SKU=${sku})`)
      }

      dispatchMobileAppEvent({
        app: "cart_checkout",
        type: desiredQuantity < entry.quantity ? "IG_REMOVE_FROM_CART" : "IG_ADD_TO_CART",
        payload: {
          state: "failed",
          productId: sku,
          amount: entry.quantity,
          numberOfProducts: cart?.cartItemCount ?? 0,
        },
      })
    },
  })
}

export function useUpdateEntryServiceBySkuMutation(sku: string) {
  const queryClient = useQueryClient()
  const trackCartMutation = useDispatchTrackCartMutation()

  return useMutation({
    mutationKey: ["update-entry-service", sku],
    async mutationFn(serviceId: string) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/entries/${sku}/services/${serviceId}`)

      return mapCart(flattenToCart(data.cart))
    },
    onMutate() {
      const oldViewCart = queryClient.getQueryData<ViewCartDispatchEventData>([QUERY_KEY_TRACK_VIEW_CART])
      return { oldViewCart }
    },
    onSuccess(cart, _, context) {
      const oldViewCart = context?.oldViewCart
      queryClient.setQueryData([QUERY_KEY_CART], cart)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })
      const updatedEntry = cart.entries.find((entry) => entry.sku === sku)

      if (!updatedEntry) {
        throw new Error(`Cart entry mutation for added service could not be determined (SKU=${sku})`)
      }

      trackCartMutation("add_to_cart", {
        quantity: updatedEntry.quantity,
        sku,
        oldViewCart,
      })
    },
    onError(error, serviceId) {
      log.error(
        `Failed to update cart entry (SKU=${sku}) with service (SERVICE_ID=${serviceId})`,
        {},
        error instanceof Error ? error : undefined
      )

      const cart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])
      const entry = cart?.entries.find((entry) => entry.sku === sku)

      if (!entry) {
        throw new Error(`Cart entry could not be found (SKU=${sku})`)
      }
    },
  })
}

export function useRemoveEntryServiceBySkuMutation(sku: string) {
  const queryClient = useQueryClient()
  const trackCartMutation = useDispatchTrackCartMutation()

  return useMutation({
    mutationKey: ["remove-entry-service", sku],
    async mutationFn(serviceId: string) {
      const { data } = await api.delete<CartMutationResponse>(`/api/v2/cart/entries/${sku}/services/${serviceId}`)

      return mapCart(flattenToCart(data.cart))
    },
    onMutate() {
      const oldViewCart = queryClient.getQueryData<ViewCartDispatchEventData>([QUERY_KEY_TRACK_VIEW_CART])
      return { oldViewCart }
    },
    onSuccess(cart, _, context) {
      const oldViewCart = context?.oldViewCart
      queryClient.setQueryData([QUERY_KEY_CART], cart)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })
      const updatedEntry = cart.entries.find((entry) => entry.sku === sku)

      if (!updatedEntry) {
        throw new Error(`Cart entry mutation for removed service could not be determined (SKU=${sku})`)
      }

      trackCartMutation("remove_from_cart", {
        quantity: updatedEntry.quantity,
        sku,
        oldViewCart,
      })
    },
    onError(error, serviceId) {
      log.error(
        `Failed to remove service (SERVICE_ID=${serviceId}) from cart entry (SKU=${sku})`,
        {},
        error instanceof Error ? error : undefined
      )

      const cart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])
      const entry = cart?.entries.find((entry) => entry.sku === sku)

      if (!entry) {
        throw new Error(`Cart entry could not be found (SKU=${sku}) to update (SERVICE_ID=${serviceId})`)
      }
    },
  })
}

export function useRemoveEntryBySkuMutation(sku: string) {
  const queryClient = useQueryClient()
  const trackCartMutation = useDispatchTrackCartMutation()

  return useMutation({
    mutationKey: ["remove-entry", sku],
    async mutationFn() {
      const { data } = await api.delete<CartMutationResponse>(`/api/v2/cart/entries/${sku}`)

      return mapCart(flattenToCart(data))
    },
    onMutate() {
      const oldViewCart = queryClient.getQueryData<ViewCartDispatchEventData>([QUERY_KEY_TRACK_VIEW_CART])
      const oldCart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])!

      queryClient.setQueryData([QUERY_KEY_CART], {
        ...oldCart,
        entries: oldCart.entries.filter((entry) => entry.sku !== sku),
      })

      return { oldViewCart, oldCart }
    },
    onSuccess(cart, _, context) {
      const oldViewCart = context?.oldViewCart
      const oldCart = context?.oldCart
      const updatedEntry = oldCart?.entries.find((entry) => entry.sku === sku)

      // Update the cache if no other "remove-entry" mutation is in flight.
      if (queryClient.isMutating({ mutationKey: ["remove-entry"] }) <= 1) {
        queryClient.setQueryData([QUERY_KEY_CART], cart)
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })
      }

      dispatchMobileAppEvent({
        app: "cart_checkout",
        type: "IG_REMOVE_FROM_CART",
        payload: {
          state: "success",
          productId: sku,
          amount: 0,
          numberOfProducts: cart.cartItemCount,
        },
      })

      if (!updatedEntry) {
        throw new Error(`Cart entry mutation for removed entry could not be determined (SKU=${sku})`)
      }

      trackCartMutation("remove_from_cart", {
        quantity: updatedEntry.quantity,
        sku,
        oldViewCart,
      })
    },
    onError(error, _, context) {
      log.error(`Failed to remove cart entry (SKU=${sku})`, {}, error instanceof Error ? error : undefined)

      queryClient.setQueryData([QUERY_KEY_CART], context?.oldCart)

      const entry = context?.oldCart.entries.find((entry) => entry.sku === sku)

      if (!entry) {
        throw new Error(`Cart entry could not be found (SKU=${sku})`)
      }

      dispatchMobileAppEvent({
        app: "cart_checkout",
        type: "IG_REMOVE_FROM_CART",
        payload: {
          state: "failed",
          productId: sku,
          amount: entry.quantity,
          numberOfProducts: context?.oldCart.cartItemCount ?? 0,
        },
      })
    },
  })
}

export function useApplyStickerBySkuAndBarcodeMutation(sku: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["apply-sticker", sku],
    networkMode: "online",
    async mutationFn(barcode: string) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/entries/${sku}/stickers/${barcode}`)

      return mapCart(flattenToCart(data))
    },
    onMutate(barcode) {
      const oldCart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])!

      queryClient.setQueryData([QUERY_KEY_CART], {
        ...oldCart,
        entries: oldCart.entries.map((entry) => ({
          ...entry,
          stickers: entry.stickers.map((sticker) => ({
            ...sticker,
            isApplied: sticker.barcode === barcode ? entry.sku === sku : sticker.isApplied,
          })),
        })),
      })

      return { oldCart }
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
    onError(error, _, context) {
      log.error("Failed to apply a sticker discount", {}, error instanceof Error ? error : undefined)

      if (context?.oldCart) {
        queryClient.setQueryData([QUERY_KEY_CART], context?.oldCart)
      }
    },
  })
}

export function useToggleActionCodeMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn({ actionCode, isApplied }: { actionCode: string; isApplied: boolean }) {
      const { data } = await api.request<CartMutationResponse>({
        url: `/api/v2/cart/actioncodes/${actionCode}`,
        method: isApplied ? "DELETE" : "PUT",
      })

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
    onError(error) {
      log.error("Failed to apply a sticker discount", {}, error instanceof Error ? error : undefined)
    },
  })
}
