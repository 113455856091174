import { GenericErrorBoundary } from "components/GenericErrorBoundary"
import type { ReactNode } from "react"
import { Suspense } from "react"

interface MainProps {
  children: ReactNode
}

export function Main({ children }: MainProps) {
  return (
    <GenericErrorBoundary>
      <main className="flex flex-1 flex-col">
        <Suspense fallback={null}>{children}</Suspense>
      </main>
    </GenericErrorBoundary>
  )
}
