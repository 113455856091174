import { clsx, type ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const isArbitraryValue = (classPart: string) => /^\[.+\]$/.test(classPart);
const customTwMerge = extendTailwindMerge({
  override: {
    classGroups: {
      "font-size": [
        {
          text: [
            "30",
            "40",
            "50",
            "75",
            "100",
            "200",
            "300",
            "400",
            "500",
            "600",
            "700",
            "800",
            "900",
            "1000",
            "10",
            "11",
            "12",
            "14",
            "16",
            "18",
            "20",
            "23",
            "24",
            "26",
            "28",
            "29",
            "33",
            "37",
            "42",
            "47",
            "base",
            isArbitraryValue,
          ],
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}
