function s() {
  return typeof window < "u" && (window == null ? void 0 : window.navigator.userAgent.includes("IGApp"));
}
function i(n) {
  const e = s();
  return n.includes("app") && e;
}
export {
  i as a,
  s as u
};
