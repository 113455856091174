import { useQuery } from "@tanstack/react-query"
import { api } from "api"
import { useCheckoutPageQuery } from "api/checkout-page"
import type { ShipmentsResponse } from "features/checkout/types"
import { adaptShipmentsData } from "helpers/adaptShipmentsData"

export const QUERY_KEY_SHIPMENTS = "shipments"

export function useShipmentsQuery() {
  const cartSummary = useCheckoutPageQuery().data?.cartSummary

  return useQuery({
    queryKey: [QUERY_KEY_SHIPMENTS],
    queryFn: async ({ signal }) => {
      const { data } = await api.get<ShipmentsResponse>("/api/checkout/data/shipments", { signal })

      return {
        delivery: adaptShipmentsData(data.shipments.delivery?.deliveryCalendars ?? [], cartSummary),
        pickup: adaptShipmentsData(data.shipments.pickup?.deliveryCalendars ?? [], cartSummary),
      }
    },
  })
}
