import { isMobileApp } from "@intergamma/common/device"
import { Suspense } from "react"
import { MainHeader } from "./MainHeader"
import { SimplifiedHeader } from "./SimplifiedHeader"

interface HeaderProps {
  variant?: "main" | "simplified"
}

export function Header({ variant = "main" }: HeaderProps) {
  if (isMobileApp) {
    return null
  }

  return (
    <>
      {variant === "simplified" ? (
        <Suspense fallback={<div style={{ height: 60 }} />}>
          <SimplifiedHeader />
        </Suspense>
      ) : (
        <Suspense fallback={<div style={{ height: 125 }} />}>
          <MainHeader />
        </Suspense>
      )}
    </>
  )
}
