import { gamma_be } from "config/formula/gamma_be"
import { gamma_nl } from "config/formula/gamma_nl"
import { karwei_nl } from "config/formula/karwei_nl"
import type { SupportedLanguage } from "types"

const formulas = [gamma_be, gamma_nl, karwei_nl]
const formula = formulas.find((formula) => window.location.hostname.endsWith(formula.baseDomain))

if (!formula) {
  throw new Error(`Unable to resolve formula config`)
}

export const formulaConfig = formula

export const isKarwei = formulaConfig.brand === "karwei"
export const isGamma = formulaConfig.brand === "gamma"

export const supportedLanguages = formulaConfig.locales.map((locale) => locale.substring(2, 0) as SupportedLanguage)
